import { TextField } from "@mui/material";
import CustomText from "./CustomText";

const styles = {
  container: {
    display: "flex",
    flexDirection: "column",
  },
  error: {
    container: {
      height: "0.5em",
    },
    message: {
      color: "#FF0033",
      fontSize: 14,
    },
  },
};

export default function CustomTextField({
  formik,
  type = "text",
  label,
  name,
  placeholder,
  passwordEntry,
  onChange,
  value,
  values,
  errors,
  touched,
  disabled = false,
  fullWidth = true,
  required = false,
  maxLength,
  borderless,
  style,
  props,
}) {
  return (
    <div style={styles.container}>
      <div style={styles.error.container}>
        <CustomText style={{ marginBottom: "0.2em" }}>{label}</CustomText>
      </div>
      <TextField
        label={label}
        autoCapitalize={false}
        autoCorrect={false}
        disabled={disabled}
        type={passwordEntry ? "password" : type}
        placeholder={placeholder}
        value={formik ? values[name] : value}
        onChange={formik ? onChange(name) : onChange}
        // fullWidth={fullWidth}
        required={required}
        style={style}
        inputProps={{
          style: { fontFamily: "Open Sans" },
          maxLength: maxLength,
        }} //added by DY
        sx={{
          "& .MuiOutlinedInput-root:hover": {
            "& > fieldset": {
              borderColor: "#a3a3a3",
            },
          },
          "& fieldset": borderless ? { border: "none" } : {},
        }} //added by DY
        {...props}
      />
      {formik && (
        <div style={styles.error.container}>
          <CustomText style={styles.error.message}>
            {touched?.[name] ? errors?.[name] : ""}
          </CustomText>
        </div>
      )}
    </div>
  );
}
