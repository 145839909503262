// USER
export const LOGIN = "LOGIN";
export const SIGNUP = "SIGNUP";
export const LOGOUT = "LOGOUT";
export const UPDATE_USER = "UDPATE_USER";

export const ADD_USER = "ADD_USER";
export const REDUCE_UNREAD = "REDUCE_UNREAD";

export const WORKSPACES_OF_USER = "WORKSPACES_OF_USER";
export const THREADS_OF_USER = "THREADS_OF_USER";

export const SAVE_UPVOTE_TO_STORE = "SAVE_UPVOTE_TO_STORE";
export const SAVE_DOWNVOTE_TO_STORE = "SAVE_DOWNVOTE_TO_STORE";

//WORKSPACE
export const SAVE_WORKSPACE_TO_STORE = "SAVE_WORKSPACE_TO_STORE";
export const VOTE_THREAD_IN_WORKSPACE = "VOTE_THREAD_IN_WORKSPACE";

//Thread
export const SAVE_THREAD_TO_STORE = "SAVE_WORKSPACE_TO_THREAD";
export const ADD_REPLY_TO_STORE = "ADD_REPLY_TO_STORE";

//Notifications
export const ADD_NOTIFICATION_TO_STORE = "ADD_NOTIFICATION_TO_STORE";
export const MARK_NOTIFICATION_AS_READ = "MARK_NOTIFICATION_AS_READ";
