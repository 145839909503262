import axios from "axios";
import { dev, prod } from "../env";

const instance = axios.create({
  baseURL: prod.baseURL,
});

instance.defaults.headers.common["Content-Type"] = "application/json";

export default instance;
