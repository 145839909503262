import { Outlet, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useState, useEffect } from "react";
import { onAuthStateChanged } from "firebase/auth";
import Skeleton from "@mui/material/Skeleton";

import CustomText from "../CustomText";
import BellSVG from "../../assets/Bell.svg";
import MagnifyingGlassSVG from "../../assets/MagnifyingGlass.svg";
import ACMELogoSVG from "../../assets/ACMELogo.svg";
import "./TopBar.css";
import CustomButton from "../CustomButton";
import { auth } from "../../firebase/init";

import { loginAction } from "../../redux/actions/userActions";
import { addNotificationsToStoreAction } from "../../redux/actions/notificationActions";

import { loginUser } from "../../api/user";
import { getWorkspaceById } from "../../api/workspace";
import { getWorkspacesByUserId } from "../../api/workspace";
import { getThreadsByUserId } from "../../api/thread";
import { getNotifications } from "../../api/notification";

import {
  addUserAction,
  threadsOfUserAction,
  workspacesOfUserAction,
} from "../../redux/actions/userActions";
import { saveWorkspaceToStoreAction } from "../../redux/actions/workspaceActions";
import SearchComponent from "../Search/SearchComponent";

export default function TopBar({ showOurName = false }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { workspaceId } = useParams();
  const user = useSelector((state) => state.auth);
  const currentWorkspaceName = useSelector(
    (state) => state.workspace[workspaceId]?.workspaceName
  );

  const [popupSearchOpen, setPopupSearchOpen] = useState(false);

  useEffect(() => {
    // Re-fetch the user information on load (might not be saved in Redux)
    const uid = localStorage.getItem("uid");
    if (!!uid) {
      //If a uid is saved, means someone is logged in, so get the info
      // console.log("inside top bar");
      loginUser().then((res) => {
        dispatch(loginAction({ uid: uid, data: res }));
        dispatch(addUserAction({ uid: res.username, data: res })); //this is a UX optimziation since profile page now uses username
        const username = res.username;
        getThreadsByUserId(res.id).then((res) => {
          const threadsResult = {
            threads: res,
            uid: username,
          };
          dispatch(threadsOfUserAction(threadsResult));
        });
        getWorkspacesByUserId(res.id).then((res) => {
          const workspacesResult = {
            workspaces: res,
            uid: username,
          };
          dispatch(workspacesOfUserAction(workspacesResult));
        });
        getNotifications().then((res) => {
          dispatch(addNotificationsToStoreAction(res));
        });
      });
    }
  }, []);

  // //this one is a UX optimization to pre-load in case navigate to user profile
  // useEffect(() => {
  //   //Get all the workspaces the user manages
  //   const uid = localStorage.getItem("uid");
  //   getWorkspacesByUserId(uid).then((res) => {
  //     const workspacesResult = {
  //       workspaces: res,
  //       uid: uid,
  //     };
  //     dispatch(workspacesOfUserAction(workspacesResult));
  //   });
  // }, []);

  useEffect(() => {
    // Re-fetch the workspace information on load (might not be saved in Redux)
    if (!!workspaceId) {
      getWorkspaceById(workspaceId).then((res) =>
        dispatch(saveWorkspaceToStoreAction(res))
      );
    }
  }, [workspaceId]);

  useEffect(() => {
    // console.log(user);
    if (user.onboarded === false) {
      navigate("/onboard");
    }
  }, [user]);

  const [width, setWidth] = useState(window.innerWidth);

  //this is temp code to handle mobile, should be fixed later
  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }
  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  return (
    <>
      <div className="NavBar">
        {/* {showOurName ? (
          <div
            className="NavBarSection"
            // onClick={() => navigate("/w/" +)}
          >
            <CustomText
              style={{ fontWeight: "700", fontSize: "20px", color: "#555555" }}
            >
              Blabberblabber
            </CustomText>
          </div>
        ) : ( */}
        <div
          className="NavBarSection"
          onClick={() => navigate("/w/" + workspaceId)}
          style={{ cursor: "pointer" }}
        >
          {/* <img
            src={ACMELogoSVG}
            style={{ height: "2em", width: "2em", marginRight: "1em" }}
            alt="ACMELogoSVG"
          /> */}
          <CustomText
            style={{
              fontWeight: "700",
              fontSize: "20px",
              color: "#555555",
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
            }}
          >
            {currentWorkspaceName}
          </CustomText>
        </div>
        {/* )} */}
        {width > 768 && (
          <>
            {popupSearchOpen ? (
              <SearchComponent
                popupSearchOpen={popupSearchOpen}
                setPopupSearchOpen={setPopupSearchOpen}
                currentWorkspaceName={currentWorkspaceName}
                workspaceId={workspaceId}
              />
            ) : (
              <div
                className="NavBarSection"
                onClick={() => setPopupSearchOpen(true)}
              >
                <div
                  style={{
                    border: "1px solid #cdcdcd",
                    height: "2em",
                    padding: "0em 1em",
                    width: "100%",
                    borderRadius: "8px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-start",
                    cursor: "pointer",
                  }}
                >
                  <img
                    src={MagnifyingGlassSVG}
                    style={{
                      height: "0.8em",
                      width: "0.8em",
                      marginRight: "0.5em",
                    }}
                    alt="MagnifyingGlassSVG"
                  />
                  <CustomText
                    style={{
                      fontWeight: "400",
                      fontSize: "12px",
                      color: "#7e7e7e",
                    }}
                  >
                    Search for something...
                  </CustomText>
                </div>
              </div>
            )}
          </>
        )}
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
            flex: 1,
          }}
        >
          {localStorage.getItem("uid") ? (
            <>
              {user?.pfp ? (
                <>
                  <div
                    className="NavBarSectionRight"
                    onClick={() => navigate("/u/" + user.username)}
                    style={{ cursor: "pointer" }}
                  >
                    {width > 768 && (
                      <CustomText
                        style={{
                          fontWeight: "400",
                          fontSize: "16px",
                          color: "#555555",
                          marginRight: "1em",
                        }}
                      >
                        {user?.usernameStyled}
                      </CustomText>
                    )}
                    <img
                      src={`data:image/svg+xml;utf8,${encodeURIComponent(
                        user?.pfp
                      )}`}
                      style={{ height: "2em", width: "2em" }}
                      alt="PFPSVG"
                    />
                  </div>{" "}
                  <div
                    className="NavBarNotification"
                    onClick={() => navigate("/notifications")}
                    style={{ cursor: "pointer", position: "relative" }}
                  >
                    <img
                      src={BellSVG}
                      style={{
                        height: "1.2em",
                        width: "1.2em",
                        // marginLeft: "1em",
                      }}
                      alt="BellSVG"
                    />
                    {!!user?.unread && (
                      <div
                        style={{
                          backgroundColor: "#6CB126",
                          height: "0.8em",
                          width: "0.8em",
                          borderRadius: "0.5em",
                          position: "absolute",
                          top: "0.5em",
                          left: "1.2em",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          // top: "50%",
                          // left: "-0.6em",
                          // left: "0%",
                        }}
                      >
                        <CustomText
                          style={{
                            fontWeight: "400",
                            fontSize: "7px",
                            color: "#FFF",
                          }}
                        >
                          {user.unread < 100 ? user.unread : ""}
                        </CustomText>
                      </div>
                    )}
                  </div>
                </>
              ) : (
                <div
                  className="NavBarSectionRight"
                  // onClick={() => navigate("/u/" + localStorage.getItem("uid"))}
                >
                  <Skeleton
                    animation="wave"
                    variant="rounded"
                    width={"6em"}
                    height={"1.5em"}
                    style={{ marginRight: "1em" }}
                  />
                  <Skeleton
                    animation="wave"
                    variant="circular"
                    width={"2em"}
                    height={"2em"}
                  />
                </div>
              )}
            </>
          ) : (
            // <div className="NavBarSection">
            <CustomButton
              style={{ backgroundColor: "#E4E4E4" }}
              onClick={() => navigate("/signup")}
            >
              <CustomText style={{ color: "#747474" }}>
                Sign Up/Login
              </CustomText>
            </CustomButton>
            // </div>
          )}
        </div>
      </div>
      <Outlet />
    </>
  );
}
