import { BrowserRouter, Route, Routes } from "react-router-dom";
import { useEffect } from "react";

import HomeScreen from "./screens/HomeScreen";
import MainScreen from "./screens/MainScreen";
import SignupScreen from "./screens/SignupScreen";
import LoginScreen from "./screens/LoginScreen";
import OnboardingScreen from "./screens/OnboardingScreen";
import NotFound from "./screens/NotFound";
import NewThreadScreen from "./screens/NewThreadScreen";
import ProfileScreen from "./screens/ProfileScreen";
import NewWorkspaceScreen from "./screens/NewWorkspaceScreen";
import ThreadScreen from "./screens/ThreadScreen";
import CheckWorkspaceExist from "./components/CheckWorkspaceExist";
import CheckThreadExist from "./components/CheckThreadExist";
import CheckUserExist from "./components/CheckUserExist";

import { useDispatch } from "react-redux";
import { loginAction, logoutAction } from "./redux/actions/userActions";

import { onAuthStateChanged } from "firebase/auth";
import { auth } from "./firebase/init";

import { loginUser } from "./api/user";
import TopBar from "./components/TopBar/TopBar";
import DiscoverWorkspacesScreen from "./screens/DiscoverWorkspacesScreen";
import TermsScreen from "./screens/TermsScreen";
import PrivacyPolicyScreen from "./screens/PrivacyPolicyScreen";
import NotificationScreen from "./screens/NotificationScreen";

function App() {
  const dispatch = useDispatch();

  useEffect(() => {
    var unsubscribe = onAuthStateChanged(auth, async (user) => {
      // console.log("AUTH CHANGE");
      if (user) {
        // console.log(user);
        // Store uid + jwt to persistent local storage
        localStorage.setItem("uid", user.uid);
        localStorage.setItem("jwt", user.accessToken);

        // console.log("about to loginuser");
        // setTimeout(() => {}, 1000);
        // Store user data in Redux store
        // loginUser().then((res) => {
        //   // console.log(res);
        //   dispatch(loginAction({ uid: res.username, data: res }));
        // });
      } else {
        localStorage.removeItem("uid");
        localStorage.removeItem("jwt");
        dispatch(logoutAction());
      }
    });
    return () => {
      unsubscribe();
    };
  }, []);

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/">
          <Route path="" element={<MainScreen />} />
          <Route path="home" element={<MainScreen />} />

          <Route path="" element={<TopBar showOurName={true} />}>
            <Route path="discover" element={<DiscoverWorkspacesScreen />} />
            <Route path="notifications" element={<NotificationScreen />} />

            <Route path="u">
              <Route path=":userId" element={<CheckUserExist />}>
                <Route path="" element={<ProfileScreen />} />
              </Route>

              {/* <Route path="*" element={<NotFound />} /> */}
            </Route>
            <Route path="w">
              {/* <Route path="" element={<HomeScreen />} /> */}
              <Route path=":workspaceId" element={<CheckWorkspaceExist />}>
                <Route path="" element={<HomeScreen />} />
                <Route path="t">
                  <Route path=":threadId" element={<CheckThreadExist />}>
                    <Route path="" element={<ThreadScreen />} />
                  </Route>
                </Route>
                <Route path="create" element={<NewThreadScreen />} />
              </Route>
            </Route>
          </Route>
          {/* <Route path="profile" element={<ProfileScreen />} /> */}
          <Route path="newworkspace" element={<NewWorkspaceScreen />} />
          <Route path="signup" element={<SignupScreen />} />
          <Route path="login" element={<LoginScreen />} />
          <Route path="onboard" element={<OnboardingScreen />} />
          <Route path="terms" element={<TermsScreen />} />
          <Route path="privacy" element={<PrivacyPolicyScreen />} />

          <Route path="*" element={<NotFound />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
