import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";

import { useLayoutEffect } from "react";

export default function RefAssignerPlugin( {editorRef} ) {

  const [editor] = useLexicalComposerContext();

  useLayoutEffect(() => {
    if (editorRef) {
      editorRef.current = editor;
    }
    return () => {
      editorRef.current = null;
    };
  }, [editor, editorRef])

  return null;
}
