import { Formik } from "formik";
import { useState, useRef } from "react";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import { signInWithEmailAndPassword } from "firebase/auth";

import { auth } from "../firebase/init";
import CustomText from "../components/CustomText";
import CustomTextField from "../components/CustomTextField";
import CustomButton from "../components/CustomButton";
import Body from "../components/Body/Body";
import LoadSpinner from "../components/LoadSpinner";

import "../components/TopBar/TopBar.css";
import { loginUser } from "../api/user";
import { useDispatch } from "react-redux";
import { loginAction } from "../redux/actions/userActions";

function LoginScreen() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const formikRef = useRef();
  const [loading, setLoading] = useState(false);

  const handleLogin = (email, password) => {
    setLoading(true);
    signInWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        // Signed in
        const user = userCredential.user;
        // console.log(user.accessToken);
        // console.log(user.uid);
        loginUser().then((res) => {
          dispatch(loginAction({ uid: res.username, data: res }));
          navigate("/u/" + res.username);
        });
      })
      .catch((error) => {
        // const errorCode = error.code;
        // const errorMessage = error.message;
        formikRef.current.setErrors({ password: "Invalid email or password." });
        // alert("Invalid email or password.");
        setLoading(false);
      });
  };

  return (
    <>
      {/* Top Nav Bar */}
      <>
        <div className="NavBar">
          <div className="NavBarSection">
            <CustomText
              style={{ fontWeight: "700", fontSize: "20px", color: "#555555" }}
            >
              Blabberblabber
            </CustomText>
          </div>
        </div>
      </>
      {/* Body */}
      <div
        style={{
          height: "84vh",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <div
          style={{
            border: "1px solid #CDCDCD",
            backgroundColor: "#FFF",
            boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.05)",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            padding: "3em",
            borderRadius: "8px",
            width: "20%",
          }}
        >
          <CustomText
            style={{
              fontSize: "16px",
              fontWeight: "700",
              color: "#555555",
              textAlign: "center",
              marginBottom: "1em",
            }}
          >
            Log in below. Welcome back!
          </CustomText>
          <>
            <Formik
              innerRef={formikRef}
              initialValues={{ email: "", password: "" }}
              onSubmit={(values) => {
                handleLogin(values.email, values.password);
              }}
            >
              {({ handleChange, handleSubmit, values, errors, touched }) => (
                <>
                  <div style={{ width: "100%" }}>
                    <CustomTextField
                      formik
                      name="email"
                      type="email"
                      placeholder="Email"
                      onChange={handleChange}
                      values={values}
                      errors={errors}
                      touched={touched}
                      required
                    />
                    <CustomTextField
                      formik
                      name="password"
                      type="password"
                      placeholder="Password"
                      onChange={handleChange}
                      values={values}
                      errors={errors}
                      touched={touched}
                      required
                    />
                    <CustomButton
                      style={{
                        borderRadius: "6px",
                        height: "2em",
                        backgroundColor: "#414141",
                        marginTop: "1em",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                      onClick={handleSubmit}
                      disabled={loading}
                    >
                      {loading ? (
                        <LoadSpinner />
                      ) : (
                        <CustomText
                          style={{
                            fontSize: "14px",
                            fontWeight: "400",
                            color: "#FFF",
                            textAlign: "center",
                          }}
                        >
                          Log in
                        </CustomText>
                      )}
                    </CustomButton>
                  </div>
                </>
              )}
            </Formik>
            <a
              onClick={() => navigate("/signup")}
              style={{ cursor: "pointer" }}
            >
              <CustomText
                style={{
                  fontSize: "12px",
                  fontWeight: "400",
                  color: "#555555",
                  marginTop: "2em",
                }}
              >
                Need to sign up?
              </CustomText>
            </a>
          </>
        </div>
      </div>
      {/* Bottom Bar */}
      <>
        <div className="NavBar" style={{ borderTop: "1px solid #cdcdcd" }}>
          <div className="NavBarSection">
            <CustomText
              style={{ fontWeight: "400", fontSize: "16px", color: "#949494" }}
            >
              Blabberblabber
            </CustomText>
          </div>
          <div className="NavBarSection" style={{ justifyContent: "flex-end" }}>
            <a
              href={"https://airtable.com/shrnYoM4ErhoVkcck"}
              style={{ textDecoration: "none" }}
              target="_blank"
              rel="noopener noreferrer"
            >
              <CustomText
                style={{
                  fontWeight: "400",
                  fontSize: "16px",
                  color: "#949494",
                  marginRight: "2em",
                }}
              >
                Support
              </CustomText>
            </a>
            <div onClick={() => navigate("/terms")}>
              <CustomText
                style={{
                  fontWeight: "400",
                  fontSize: "16px",
                  color: "#949494",
                  marginRight: "2em",
                  cursor: "pointer",
                }}
              >
                Terms of Service
              </CustomText>
            </div>
            <div onClick={() => navigate("/privacy")}>
              <CustomText
                style={{
                  fontWeight: "400",
                  fontSize: "16px",
                  color: "#949494",
                  cursor: "pointer",
                }}
              >
                Privacy Policy
              </CustomText>
            </div>
          </div>
        </div>
      </>
    </>
  );
}

export default LoginScreen;
