import {
  SAVE_WORKSPACE_TO_STORE,
  VOTE_THREAD_IN_WORKSPACE,
} from "../actions/types";
import { extractObjectFromObject } from "../../lib/transformationUtil";

const INITIAL_STATE = {};

const workspaceReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SAVE_WORKSPACE_TO_STORE:
      const result = extractObjectFromObject(action.payload);
      // console.log({
      //   ...state,
      //   [result.id]: result.data,
      // });s
      return {
        ...state,
        [result.id]: result.data,
      };
    case VOTE_THREAD_IN_WORKSPACE:
      const workspaceThreads = state[action.payload.workspaceId].threads;
      const newThreads = workspaceThreads.map((thread) => {
        if (thread.id === action.payload.threadId) {
          if (action.payload.type === "upvote") {
            //handle upvote
            if (thread.upvotes[action.payload.userId]) {
              delete thread.upvotes[action.payload.userId];
            } else {
              thread.upvotes[action.payload.userId] = true;
            }
          } else if (action.payload.type === "downvote") {
            //handle downvote
            if (thread.upvotes[action.payload.userId] === false) {
              delete thread.upvotes[action.payload.userId];
            } else {
              thread.upvotes[action.payload.userId] = false;
            }
          }
          return thread;
        } else {
          //this can be optimized by ending loop when found
          return thread;
        }
      });
      const newWorkspace = {
        ...state[action.payload.workspaceId],
        threads: newThreads,
      };
      return { ...state, [newWorkspace.id]: newWorkspace };

    default:
      return state;
  }
};

export default workspaceReducer;
