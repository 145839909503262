import { useState, useEffect } from "react";
import Skeleton from "@mui/material/Skeleton";

import TopBar from "../components/TopBar/TopBar";
import PFPSVG from "../assets/PFP.svg";
import CustomText from "../components/CustomText";

import GateSVG from "../assets/Gate.svg";
import GearSVG from "../assets/Gear.svg";
import TrophySVG from "../assets/Megaphone.svg";

import WorkspacesGrid from "../components/WorkspacesGrid/WorkspacesGrid";
import BodyThread from "../components/Body/BodyThread";
import BodyNoThread from "../components/Body/BodyNoThreads";

import { getThreadsByUserId } from "../api/thread";
import { getWorkspacesByUserId } from "../api/workspace";
import { getProfileById, getProfileByUsername } from "../api/user";

import { auth } from "../firebase/init";
import { signOut } from "firebase/auth";

import { useNavigate, useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
  addUserAction,
  threadsOfUserAction,
  workspacesOfUserAction,
} from "../redux/actions/userActions";
import { circularProgressClasses } from "@mui/material";

function ProfileScreen() {
  const { userId } = useParams();
  const { username } = useParams();

  const selfUser = useSelector((state) => state.auth);

  const user = useSelector((state) => state.user[userId]);
  const userWorkspaces = useSelector((state) => state.userWorkspaces[userId]);
  const userThreads = useSelector((state) => state.userThreads[userId]);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [profileCategory, setProfileCategory] = useState("workspaces");
  const [userIdReceived, setUserIdReceived] = useState(false);

  useEffect(() => {
    // getProfileById(userId).then((res) => {
    //   // console.log(res);
    //   dispatch(addUserAction({ uid: userId, data: res }));
    // });
    getProfileByUsername(userId).then((res) => {
      dispatch(addUserAction({ uid: userId, data: res }));
      // setUserIdReceived(true); //this is not used
      getThreadsByUserId(res.id).then((res) => {
        const threadsResult = {
          threads: res,
          uid: userId,
        };
        dispatch(threadsOfUserAction(threadsResult));
      });
      getWorkspacesByUserId(res.id).then((res) => {
        const workspacesResult = {
          workspaces: res,
          uid: userId,
        };
        dispatch(workspacesOfUserAction(workspacesResult));
      });
    });
  }, [userId]);

  // useEffect(() => {
  //   getThreadsByUserId(userId).then((res) => {
  //     const threadsResult = {
  //       threads: res,
  //       uid: userId,
  //     };
  //     dispatch(threadsOfUserAction(threadsResult));
  //   });
  // }, []);

  // useEffect(() => {
  //   //Get all the workspaces the user manages
  //   getWorkspacesByUserId(userId).then((res) => {
  //     const workspacesResult = {
  //       workspaces: res,
  //       uid: userId,
  //     };
  //     dispatch(workspacesOfUserAction(workspacesResult));
  //   });
  // }, []);

  // console.log(Object.values(userWorkspaces));

  return (
    <>
      {/* <TopBar /> */}
      {/* {userIdReceived && ( */}
      <div
        style={{
          display: "flex",
          height: "calc(100vh - 80px)",
          overflowY: "scroll",
        }}
      >
        <div
          style={{
            flex: "1",
            borderRight: "1px solid #cdcdcd",
            backgroundColor: "#fffffa",
            height: "100%",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              padding: "3em 2em",
              borderBottom: "1px solid #cdcdcd",
            }}
          >
            {!!user ? (
              <>
                <img
                  src={`data:image/svg+xml;utf8,${encodeURIComponent(
                    userId === localStorage.getItem("uid")
                      ? selfUser.pfp
                      : user.pfp
                  )}`}
                  style={{ width: "50%" }}
                  alt="PFPSVG"
                />
                <CustomText
                  style={{
                    color: "#747474",
                    fontSize: "16px",
                    fontWeight: "700",
                    marginTop: "1.5em",
                  }}
                >
                  @
                  {userId === localStorage.getItem("uid")
                    ? selfUser.usernameStyled
                    : user.usernameStyled}
                </CustomText>
                <CustomText
                  style={{
                    color: "#7E7E7E",
                    fontSize: "14px",
                    fontWeight: "400",
                    marginTop: "1.5em",
                  }}
                >
                  Joined{" "}
                  {new Date(user.createdAt).toLocaleString("default", {
                    month: "short",
                    year: "numeric",
                  })}
                </CustomText>
              </>
            ) : (
              <>
                <Skeleton
                  animation="wave"
                  variant="circular"
                  style={{ width: "50%", height: 0, paddingBottom: "50%" }}
                />
                <Skeleton
                  animation="wave"
                  variant="rounded"
                  style={{
                    marginTop: "1.5em",
                    height: "1.5em",
                    width: "8em",
                  }}
                />
                <Skeleton
                  animation="wave"
                  variant="rounded"
                  style={{
                    marginTop: "1.5em",
                    height: "1.5em",
                    width: "4em",
                  }}
                />
              </>
            )}
          </div>
          <div style={{ padding: "4em" }}>
            {localStorage.getItem("uid") === user?.id ? (
              <>
                <CustomText
                  style={{
                    color: "#7E7E7E",
                    fontSize: "12px",
                    fontWeight: "400",
                    marginTop: "1.5em",
                    textAlign: "center",
                    fontStyle: "italic",
                  }}
                >
                  You haven't earned any medallions yet. When you receive
                  medallions, they will be displayed here.
                </CustomText>
              </>
            ) : (
              <>
                {" "}
                <CustomText
                  style={{
                    color: "#7E7E7E",
                    fontSize: "12px",
                    fontWeight: "400",
                    marginTop: "1.5em",
                    textAlign: "center",
                    fontStyle: "italic",
                  }}
                >
                  No medallions to display.
                </CustomText>
              </>
            )}
          </div>
        </div>
        <div style={{ flex: "4", padding: "0em 5em", overflowY: "scroll" }}>
          <div
            className="BodySortBlock"
            style={{ justifyContent: "space-around" }}
          >
            <div style={{ display: "flex" }}>
              <div
                className={
                  profileCategory === "workspaces"
                    ? "BodySortButton BodySortButtonSelected"
                    : "BodySortButton"
                }
                style={{ width: "10em" }}
                onClick={() => setProfileCategory("workspaces")}
              >
                <img
                  src={GateSVG}
                  style={{
                    height: "1em",
                    width: "1em",
                    marginRight: "0.5em",
                  }}
                  alt="GateSVG"
                />
                <CustomText style={{ color: "#7E7E7E", fontWeight: "700" }}>
                  Workspaces
                </CustomText>
              </div>
              <div
                className={
                  profileCategory === "posts"
                    ? "BodySortButton BodySortButtonSelected"
                    : "BodySortButton"
                }
                style={{ width: "10em" }}
                onClick={() => setProfileCategory("posts")}
              >
                <img
                  src={TrophySVG}
                  style={{
                    height: "1em",
                    width: "1em",
                    marginRight: "0.5em",
                  }}
                  alt="TrophySVG"
                />
                <CustomText style={{ color: "#7E7E7E", fontWeight: "700" }}>
                  Posts
                </CustomText>
              </div>
              {localStorage.getItem("uid") === user?.id ? (
                <>
                  <div
                    className={
                      profileCategory === "settings"
                        ? "BodySortButton BodySortButtonSelected"
                        : "BodySortButton"
                    }
                    style={{ width: "10em" }}
                    onClick={() => setProfileCategory("settings")}
                  >
                    <img
                      src={GearSVG}
                      style={{
                        height: "1em",
                        width: "1em",
                        marginRight: "0.5em",
                      }}
                      alt="GearSVG"
                    />
                    <CustomText style={{ color: "#7E7E7E", fontWeight: "700" }}>
                      Settings
                    </CustomText>
                  </div>
                </>
              ) : (
                <></>
              )}
            </div>
          </div>
          {profileCategory === "workspaces" && (
            <>
              {localStorage.getItem("uid") === user?.id ? (
                <>
                  <CustomText
                    style={{
                      color: "#747474",
                      fontSize: "20px",
                      fontWeight: "700",
                      marginTop: "1.5em",
                    }}
                  >
                    Workspaces You Manage
                  </CustomText>
                  <div style={{ marginTop: "2em" }}>
                    <WorkspacesGrid
                      managedWorkspaces={true}
                      workspacesData={userWorkspaces}
                      ownWorkspace={true}
                    />
                  </div>
                  <CustomText
                    style={{
                      color: "#747474",
                      fontSize: "20px",
                      fontWeight: "700",
                      marginTop: "3em",
                    }}
                  >
                    Workspaces You've Participated In
                  </CustomText>
                  <div style={{ marginTop: "2em" }}>
                    <WorkspacesGrid
                      ownWorkspace={true}
                      workspacesData={userWorkspaces}
                    />
                  </div>
                </>
              ) : (
                <>
                  {" "}
                  <CustomText
                    style={{
                      color: "#747474",
                      fontSize: "20px",
                      fontWeight: "700",
                      marginTop: "1.5em",
                    }}
                  >
                    Workspaces They Manage
                  </CustomText>
                  <div style={{ marginTop: "2em" }}>
                    <WorkspacesGrid
                      managedWorkspaces={true}
                      workspacesData={userWorkspaces}
                    />
                  </div>
                  <CustomText
                    style={{
                      color: "#747474",
                      fontSize: "20px",
                      fontWeight: "700",
                      marginTop: "3em",
                    }}
                  >
                    Workspaces They've Participated In
                  </CustomText>
                  <div style={{ marginTop: "2em" }}>
                    <WorkspacesGrid workspacesData={userWorkspaces} />
                  </div>
                </>
              )}
            </>
          )}
          {profileCategory === "posts" && (
            <>
              {!!userThreads && Object.keys(userThreads).length !== 0 ? (
                <BodyThread threadsData={userThreads} profilePage={true} />
              ) : (
                <BodyNoThread profilePage={true} />
              )}
            </>
          )}
          {profileCategory === "settings" && (
            <>
              <CustomText
                style={{
                  color: "#7E7E7E",
                  fontWeight: "400",
                  marginBottom: "2em",
                  fontSize: "16px",
                }}
              >
                {user?.email}
              </CustomText>
              <div
                className="AddPostButton"
                onClick={() => {
                  signOut(auth)
                    .then(() => {
                      navigate("/signup");
                      // Sign-out successful.
                    })
                    .catch((error) => {
                      // An error happened.
                      // console.log(error);
                      alert(
                        "Sorry, that didn't seem to work. Please try again."
                      );
                    });
                }}
              >
                <CustomText style={{ color: "#7E7E7E", fontWeight: "700" }}>
                  Log Out
                </CustomText>
              </div>
              <CustomText
                style={{
                  color: "#7E7E7E",
                  fontWeight: "400",
                  marginTop: "2em",
                  fontSize: "12px",
                }}
              >
                To ask for help or submit feedback, please contact
                hey@blabberblabber.com.
              </CustomText>
              <CustomText
                style={{
                  color: "#7E7E7E",
                  fontWeight: "400",
                  marginTop: "2em",
                  fontSize: "10px",
                  textDecoration: "underline",
                }}
              >
                Terms of Service
              </CustomText>
              <CustomText
                style={{
                  color: "#7E7E7E",
                  fontWeight: "400",
                  marginTop: "2em",
                  fontSize: "10px",
                  textDecoration: "underline",
                }}
              >
                Privacy Policy
              </CustomText>
            </>
          )}
        </div>
      </div>
      {/* )} */}
    </>
  );
}

export default ProfileScreen;
