const shortenNumber = (number) => {
  if (!number) return 0;
  const scale = Math.floor(Math.log10(number) / 3);
  let unit = "";
  switch (scale) {
    case 1:
      unit = "k";
      break;
    case 2:
      unit = "m";
      break;
    case 3:
      unit = "b";
      break;
    case 4:
      unit = "t";
      break;
    default:
      unit = "";
  }
  const sigfigs = number / Math.pow(1000, scale);
  return `${unit === "" ? sigfigs : sigfigs.toFixed(1)}${unit}`;
};

const timeSince = (date) => {
  var seconds = Math.floor((new Date() - date) / 1000);

  var interval = seconds / 31536000;

  if (interval > 1) {
    return Math.floor(interval) + " y";
  }
  interval = seconds / 2592000;
  if (interval > 1) {
    return Math.floor(interval) + " mon";
  }
  interval = seconds / 86400;
  if (interval > 1) {
    return Math.floor(interval) + " d";
  }
  interval = seconds / 3600;
  if (interval > 1) {
    return Math.floor(interval) + " h";
  }
  interval = seconds / 60;
  if (interval > 1) {
    return Math.floor(interval) + " min";
  }
  return Math.floor(seconds) + " s";
};

function lowerCamelCase(string) {
  return string.charAt(0).toLowerCase() + string.slice(1);
}

const toLowerCamelCase = (json) => {
  if (Array.isArray(json)) {
    return json.map((val) => toLowerCamelCase(val));
  } else if (typeof json === "object" && json !== null) {
    const copy = {};
    for (const key of Object.keys(json)) {
      copy[lowerCamelCase(key)] = toLowerCamelCase(json[key]);
    }
    return copy;
  }
  return json;
};

const extractObjectFromArray = (arr) => {
  const transformed = {};
  for (const obj of arr) {
    transformed[obj.id] = obj;
  }
  return transformed;
};

const extractObjectFromObject = (obj) => {
  return {
    id: obj.id,
    data: { ...obj },
  };
};

const flattenThreads = (obj) => {
  const flattenedThreads = {};

  const flattenThreadsRecurse = (storeFlattened, toFlatten) => {
    storeFlattened = { ...storeFlattened, [toFlatten.id]: toFlatten };
    for (const thread in toFlatten.threads) {
      storeFlattened = flattenThreadsRecurse(
        storeFlattened,
        toFlatten.threads[thread]
      );
    }

    return storeFlattened;
  };

  return flattenThreadsRecurse(flattenedThreads, obj);
};

const countUpvotesMinusDownvotes = (obj) => {
  return (
    Object.values(obj).filter(Boolean).length * 2 - Object.values(obj).length
  );
};

export {
  shortenNumber,
  timeSince,
  extractObjectFromArray,
  extractObjectFromObject,
  flattenThreads,
  toLowerCamelCase,
  countUpvotesMinusDownvotes,
};
