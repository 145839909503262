import { WORKSPACES_OF_USER } from "../actions/types";
import { extractObjectFromArray } from "../../lib/transformationUtil";

const INITIAL_STATE = {};

const userWorkspacesReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case WORKSPACES_OF_USER:
      const workspacesUser = extractObjectFromArray(action.payload.workspaces);
      return {
        ...state,
        [action.payload.uid]: workspacesUser,
      };
    default:
      return state;
  }
};

export default userWorkspacesReducer;
