import { useNavigate } from "react-router-dom";

import CustomText from "../CustomText";
import UpvoteSVG from "../../assets/Upvote.svg";
import UpvoteBlueSVG from "../../assets/UpvoteBlue.svg";

import DownvoteSVG from "../../assets/Downvote.svg";
import DownvoteBlueSVG from "../../assets/DownvoteBlue.svg";
import CommentSVG from "../../assets/Comment.svg";
import PFPSVG from "../../assets/PFP.svg";

import { countUpvotesMinusDownvotes } from "../../lib/transformationUtil";
import { handleVote } from "../../lib/voteUtil";

import "./Body.css";
import { useDispatch } from "react-redux";

// const getScoreFromTimeAndVotes = (currentDate, createdAt, votes) => {
//   const hoursSince = (currentDate - createdAt) / (3.6 * 10 ** 6);

//   if (hoursSince < 3) {
//     return 16;
//   } else if (hoursSince < 6) {
//     return 14;
//   } else if (hoursSince < 12) {
//     return 12;
//   } else if (hoursSince < 24) {
//     return 10;
//   } else if (hoursSince < 48) {
//     return 2;
//   } else {
//     return 1;
//   }
// };

const getHoursSince = (createdAt) => {
  const currentDate = new Date();
  return (currentDate - createdAt) / (3.6 * 10 ** 6);
};

const getHotRanking = (a, b) => {
  const aHours = Math.floor(getHoursSince(a.createdAt) / 24);
  const bHours = Math.floor(getHoursSince(b.createdAt) / 24);

  if (aHours > bHours) {
    return 1;
  } else if (bHours > aHours) {
    return -1;
  } else {
    // const threads = b.threadCount - a.threadCount;
    // if (threads > 0) return 1;
    // if (threads < 0) return -1;
    // return b.createdAt - a.createdAt;
    const votes =
      countUpvotesMinusDownvotes(b.upvotes) -
      countUpvotesMinusDownvotes(a.upvotes);
    if (votes > 0) return 1;
    if (votes < 0) return -1;
    return b.createdAt - a.createdAt;
  }
};

export default function BodyThread({
  threadsData,
  contentCategory = "new",
  profilePage = false,
}) {
  const navigate = useNavigate();
  const dispatch = useDispatch;

  const sortFunction = (a, b, category) => {
    switch (category) {
      case "new":
        return b.createdAt - a.createdAt;
      case "hot":
        return getHotRanking(a, b);
      case "top":
        const votes =
          countUpvotesMinusDownvotes(b.upvotes) -
          countUpvotesMinusDownvotes(a.upvotes);
        if (votes > 0) return 1;
        if (votes < 0) return -1;
        return b.createdAt - a.createdAt;
      default:
        return b.createdAt - a.createdAt;
    }
  };

  return (
    <>
      {Object.values(threadsData)
        .sort((a, b) => sortFunction(a, b, contentCategory))
        .map((thread) => (
          <div
            className="BodyThreadItem"
            key={thread.id}
            onClick={() => {
              if (thread.parent) {
                // has parent means not top level
                navigate(
                  "/w/" +
                    thread.workspace.workspaceId +
                    "/t/" +
                    thread.topParent
                );
              } else {
                navigate(
                  "/w/" + thread.workspace.workspaceId + "/t/" + thread.id
                );
              }
            }}
          >
            <div style={{ flex: 1, display: "flex" }}>
              <div
                style={{
                  flex: "1",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  padding: "0em 1em",
                }}
              >
                <div
                  onClick={() =>
                    handleVote(
                      thread.id,
                      localStorage.getItem("uid"),
                      "upvote",
                      dispatch
                    )
                  }
                >
                  {thread.upvotes[localStorage.getItem("uid")] ? (
                    <img
                      src={UpvoteBlueSVG}
                      style={{ height: "1em", width: "1em" }}
                      alt="UpvoteBlueSVG"
                    />
                  ) : (
                    <img
                      src={UpvoteSVG}
                      style={{ height: "1em", width: "1em" }}
                      alt="UpvoteSVG"
                    />
                  )}
                </div>

                <CustomText
                  style={{
                    marginBlock: "0.5em",
                    color:
                      localStorage.getItem("uid") in thread.upvotes
                        ? "#5891E5"
                        : "#7E7E7E",
                    fontSize: "20px",
                  }}
                >
                  {countUpvotesMinusDownvotes(thread.upvotes)}
                </CustomText>
                <div
                  onClick={() =>
                    handleVote(
                      thread.id,
                      localStorage.getItem("uid"),
                      "downvote",
                      dispatch
                    )
                  }
                >
                  {thread.upvotes[localStorage.getItem("uid")] === false ? (
                    <img
                      src={DownvoteBlueSVG}
                      style={{ height: "1em", width: "1em" }}
                      alt="DownvoteBlueSVG"
                    />
                  ) : (
                    <img
                      src={DownvoteSVG}
                      style={{ height: "1em", width: "1em" }}
                      alt="DownvoteSVG"
                    />
                  )}
                </div>
              </div>
              <div
                style={{
                  flex: "7",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-around",
                  padding: "0em 1em",
                  // backgroundColor: "red",
                  minWidth: 0,
                }}
              >
                {thread.parent ? (
                  <>
                    <CustomText
                      style={{
                        color: "#747474",
                        fontSize: "16px",
                        fontWeight: "400",
                        marginBlock: "0em",
                        fontStyle: "italic",
                      }}
                    >
                      replied to a comment
                    </CustomText>
                    <CustomText
                      style={{
                        color: "#555555",
                        fontSize: "16px",
                        fontWeight: "400",
                        marginBlock: "0em",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                        maxWidth: "45vw",
                      }}
                    >
                      {thread.contentPlain}
                    </CustomText>
                  </>
                ) : (
                  <>
                    <CustomText
                      style={{
                        color: "#747474",
                        fontSize: "20px",
                        fontWeight: "700",
                        marginBlock: "0em",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                        maxWidth: "45vw",
                      }}
                    >
                      {thread.title}
                    </CustomText>
                    <CustomText
                      style={{
                        color: "#555555",
                        fontSize: "16px",
                        fontWeight: "400",
                        marginBlock: "0em",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                        maxWidth: "45vw",
                      }}
                    >
                      {thread.contentPlain}
                    </CustomText>
                  </>
                )}

                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <img
                    src={CommentSVG}
                    style={{ height: "0.8em", width: "0.8em" }}
                    alt="CommentSVG"
                  />
                  <CustomText
                    style={{
                      color: "#949494",
                      fontSize: "14px",
                      marginLeft: "0.5em",
                    }}
                  >
                    {thread.threadCount //legacy from some replies not having a threadCount, can be removed eventually
                      ? thread.threadCount
                      : Object.keys(thread.threads).length}{" "}
                    {thread.threadCount === 1 ? "reply" : "replies"}
                  </CustomText>
                </div>
              </div>

              <div
                style={{
                  flex: "2",
                  borderLeft: "1px solid #D9D9D9",
                  // backgroundColor: "red",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  flexDirection: "column",
                  minWidth: 0,
                  padding: "0em 2em",
                }}
              >
                {profilePage ? (
                  <CustomText
                    style={{
                      color: "#555555",
                      fontSize: "12px",
                      fontWeight: "400",
                      margin: "0em 0em 0em 0em",
                    }}
                  >
                    {thread.workspace.workspaceName}
                  </CustomText>
                ) : (
                  <>
                    <img
                      src={`data:image/svg+xml;utf8,${encodeURIComponent(
                        thread.poster.pfp
                      )}`}
                      style={{ height: "50%" }}
                      alt="PFPSVG"
                    />
                    <CustomText
                      style={{
                        color: "#555555",
                        fontSize: "12px",
                        fontWeight: "400",
                        margin: "0.5em 0em 0em 0em",
                      }}
                    >
                      {thread.poster.usernameStyled}
                    </CustomText>
                  </>
                )}
              </div>
            </div>
          </div>
        ))}
    </>
  );
}
