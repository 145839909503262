import { THREADS_OF_USER } from "../actions/types";
import {
  extractObjectFromObject,
  extractObjectFromArray,
} from "../../lib/transformationUtil";

const INITIAL_STATE = {};

const userThreadsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case THREADS_OF_USER:
      const threadsUser = extractObjectFromArray(action.payload.threads);
      return {
        ...state,
        [action.payload.uid]: threadsUser,
      };
    default:
      return state;
  }
};

export default userThreadsReducer;
