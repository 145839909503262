import {
  SAVE_THREAD_TO_STORE,
  SAVE_UPVOTE_TO_STORE,
  SAVE_DOWNVOTE_TO_STORE,
  ADD_REPLY_TO_STORE,
} from "../actions/types";
import {
  extractObjectFromObject,
  flattenThreads,
} from "../../lib/transformationUtil";

const INITIAL_STATE = {};

const threadReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SAVE_THREAD_TO_STORE:
      const flattenedResult = flattenThreads(action.payload);

      // const result = extractObjectFromObject(action.payload);
      return { ...state, ...flattenedResult };
    case ADD_REPLY_TO_STORE:
      const result = extractObjectFromObject(action.payload);

      let parent = { ...state[action.payload.parent] };
      parent.threads[action.payload.id] = action.payload;

      // console.log(action.payload);
      // console.log({
      //   ...state,
      //   [action.payload.parent]: parent,
      //   [result.id]: result.data,
      // });

      return {
        ...state,
        [action.payload.parent]: parent,
        [result.id]: result.data,
      };
    case SAVE_UPVOTE_TO_STORE:
      const upvote =
        state[action.payload.threadId].upvotes[action.payload.userId];

      const newThreadData = { ...state[action.payload.threadId] };

      if (upvote) {
        delete newThreadData.upvotes[action.payload.userId];
      } else {
        newThreadData.upvotes[action.payload.userId] = true;
      }
      return {
        ...state,
        [action.payload.threadId]: newThreadData,
      };
    case SAVE_DOWNVOTE_TO_STORE:
      const downvote =
        state[action.payload.threadId].upvotes[action.payload.userId];

      const newThreadDataDownvote = { ...state[action.payload.threadId] };

      if (downvote === false) {
        delete newThreadDataDownvote.upvotes[action.payload.userId];
      } else {
        newThreadDataDownvote.upvotes[action.payload.userId] = false;
      }
      return {
        ...state,
        [action.payload.threadId]: newThreadDataDownvote,
      };
    default:
      return state;
  }
};

export default threadReducer;
