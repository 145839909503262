import {
  SAVE_THREAD_TO_STORE,
  SAVE_UPVOTE_TO_STORE,
  SAVE_DOWNVOTE_TO_STORE,
  ADD_REPLY_TO_STORE,
} from "./types";

export const saveThreadToStoreAction = (model) => async (dispatch) => {
  dispatch({ type: SAVE_THREAD_TO_STORE, payload: model });
};

export const saveUpvoteToStoreAction = (model) => async (dispatch) => {
  dispatch({ type: SAVE_UPVOTE_TO_STORE, payload: model });
};

export const saveDownvoteToStoreAction = (model) => async (dispatch) => {
  dispatch({ type: SAVE_DOWNVOTE_TO_STORE, payload: model });
};

export const addReplyToStoreAction = (model) => async (dispatch) => {
  dispatch({ type: ADD_REPLY_TO_STORE, payload: model });
};
