import { useState, useEffect } from "react";
import { TextField } from "@mui/material";

import useDebounce from "../../lib/useDebounce";
import MagnifyingGlassSVG from "../../assets/MagnifyingGlass.svg";
import CustomText from "../CustomText";
import { searchByQuery, searchInWorkspaceByQuery } from "../../api/search";
import SearchResults from "./SearchResults";

function SearchComponent({
  popupSearchOpen,
  setPopupSearchOpen,
  currentWorkspaceName,
  workspaceId = null,
}) {
  // console.log(workspaceId);
  const [searchResults, setSearchResults] = useState();
  const [workspaceSearchResults, setWorkspaceSearchResults] = useState();
  const [query, setQuery] = useState();
  const [workspaceQuery, setWorkspaceQuery] = useState();
  const [searchFilters, setSearchFilters] = useState(
    !!workspaceId ? ["postsInWorkspace"] : ["workspaces"]
  ); //["postInWorkspace", "workspaces", "posts"]

  const debouncedSearch = useDebounce(query, 500);
  const debouncedSearchWorkspace = useDebounce(workspaceQuery, 500);

  useEffect(() => {
    if (
      //if workspaces or posts is selected
      searchFilters.includes("workspaces") ||
      searchFilters.includes("posts")
    ) {
      if (debouncedSearch) {
        searchByQuery(query).then((res) => setSearchResults(res));
      }
    }
  }, [debouncedSearch, searchFilters]);

  useEffect(() => {
    if (searchFilters.includes("postsInWorkspace")) {
      //if posts IN workspace is selected
      if (debouncedSearchWorkspace) {
        // console.log(workspaceId);
        searchInWorkspaceByQuery(workspaceId, workspaceQuery).then((res) => {
          // console.log(res);
          setWorkspaceSearchResults(res);
        });
      }
    }
  }, [debouncedSearchWorkspace, searchFilters]);

  return (
    <>
      <div
        style={{
          backgroundColor: "rgba(0, 0, 0, 0.4)",
          height: "100vh",
          width: "100vw",
          position: "absolute",
          top: "0px",
          left: "0px",

          display: "flex",
          justifyContent: "center",
          zIndex: "3",
        }}
        onClick={() => setPopupSearchOpen(false)}
      >
        <div
          style={{
            backgroundColor: "#FFF",
            width: "50vw",
            borderRadius: "12px",
            position: "absolute",
            top: "20vh",
            display: "flex",
            flexDirection: "column",
            // alignItems: "center",
          }}
          onClick={(e) => e.stopPropagation()}
        >
          <div
            style={{
              // backgroundColor: "red",
              display: "flex",
              alignItems: "center",
              padding: "0em 1em",
            }}
          >
            <img
              src={MagnifyingGlassSVG}
              style={{
                height: "1em",
                width: "1em",
                // marginRight: "0.5em",
              }}
              alt="MagnifyingGlassSVG"
            />
            <TextField
              inputProps={{
                style: { fontFamily: "Open Sans" },
                // maxLength: maxLength,
              }} //added by DY
              sx={{
                "& fieldset": { border: "none" },
              }} //added by DY
              placeholder="Search for something..."
              style={{
                width: "100%",
                // borderBottom: !!searchResults ? "1px solid #cdcdcd" : "none",
                // backgroundColor: "red"
              }}
              autoFocus
              onChange={(e) => {
                setQuery(e.target.value);
                setWorkspaceQuery(e.target.value);
              }}
            />
          </div>
          <div
            style={{
              borderTop: "1px solid #cdcdcd",
              padding: "1em 0em",
            }}
          >
            <CustomText
              style={{
                color: "#555555",
                fontSize: "12px",
                fontWeight: "700",
                padding: "0em 1em",
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
              }}
            >
              I want to find:
            </CustomText>
            <div
              style={{
                display: "flex",
                padding: "1em 1em 0em 1em",
                // backgroundColor: "yellow",
              }}
            >
              {!!workspaceId && (
                <div
                  className={
                    searchFilters.includes("postsInWorkspace")
                      ? "SearchFilterButton SearchFilterButtonSelected"
                      : "SearchFilterButton"
                  }
                  onClick={() => {
                    if (searchFilters.includes("postsInWorkspace")) {
                      setSearchFilters(
                        searchFilters.filter((a) => a !== "postsInWorkspace")
                      );
                    } else {
                      setSearchFilters(
                        searchFilters.concat(["postsInWorkspace"])
                      );
                    }
                  }}
                >
                  <CustomText
                    style={{
                      color: "#555555",
                      fontSize: "12px",
                      fontWeight: "400",
                    }}
                  >
                    Posts in{" "}
                    {!!currentWorkspaceName
                      ? currentWorkspaceName
                      : "this workspace"}
                  </CustomText>
                </div>
              )}
              <div
                className={
                  searchFilters.includes("workspaces")
                    ? "SearchFilterButton SearchFilterButtonSelected"
                    : "SearchFilterButton"
                }
                onClick={() => {
                  if (searchFilters.includes("workspaces")) {
                    setSearchFilters(
                      searchFilters.filter((a) => a !== "workspaces")
                    );
                  } else {
                    setSearchFilters(searchFilters.concat(["workspaces"]));
                  }
                }}
              >
                <CustomText
                  style={{
                    color: "#555555",
                    fontSize: "12px",
                    fontWeight: "400",
                  }}
                >
                  Workspaces
                </CustomText>
              </div>
              <div
                className={
                  searchFilters.includes("posts")
                    ? "SearchFilterButton SearchFilterButtonSelected"
                    : "SearchFilterButton"
                }
                onClick={() => {
                  if (searchFilters.includes("posts")) {
                    setSearchFilters(
                      searchFilters.filter((a) => a !== "posts")
                    );
                  } else {
                    setSearchFilters(searchFilters.concat(["posts"]));
                  }
                }}
              >
                <CustomText
                  style={{
                    color: "#555555",
                    fontSize: "12px",
                    fontWeight: "400",
                  }}
                >
                  Posts Anywhere
                </CustomText>
              </div>
            </div>
          </div>
          {/* {!!searchResults && ( */}
          <SearchResults
            searchResults={searchResults}
            workspaceSearchResults={workspaceSearchResults}
            setPopupSearchOpen={setPopupSearchOpen}
            currentWorkspaceName={currentWorkspaceName}
            searchFilters={searchFilters}
            query={query}
            workspaceQuery={workspaceQuery}
          />
          {/* )} */}
        </div>
      </div>
    </>
  );
}

export default SearchComponent;
