import TelescopeSVG from "../../assets/Telescope.svg";

import CustomText from "../CustomText";

import CommentStatic from "../../lexical/CommentStatic";

import "./CommentBlock.css";
import NewThreadEditor from "../../lexical/NewThreadEditor";
import CustomButton from "../CustomButton";

import LoadSpinner from "../LoadSpinner";
import {
  timeSince,
  countUpvotesMinusDownvotes,
} from "../../lib/transformationUtil";
import CommentBlockSingle from "./CommentBlockSingle";

const styles = {
  reply: {
    commentBlock: {
      display: "flex",
      flexDirection: "row",
      padding: "0em 1em",
    },
    replyText: {
      color: "#555555",
      fontSize: 16,
    },
    replyButtonText: {
      color: "#555555",
      fontSize: 12,
      fontWeight: "bold",
      marginRight: "1em",
    },
    replyLeftColumn: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },

    verticalLine: {
      marginTop: "0.5em",
      width: "0px",
      height: "100%",
      border: "0.5px solid #E0E0E0",
    },
    contentBox: {
      marginLeft: "1em",
      flex: 1,
    },
    replyUserText: {
      margin: "0",
      fontSize: 12,
      color: "#7E7E7E",
    },
    upvoteReplyBar: {
      display: "flex",
      flexDirection: "row",
    },
  },
  subreply: {
    subreplyCommentBlock: {
      display: "flex",
      flexDirection: "row",
      paddingTop: "1em",
    },
  },
};

export default function CommentBlock({ threadPageData }) {
  return (
    <>
      {Object.values(threadPageData)
        .sort((a, b) => {
          const votes =
            countUpvotesMinusDownvotes(b.upvotes) -
            countUpvotesMinusDownvotes(a.upvotes);
          if (votes > 0) return 1;
          if (votes < 0) return -1;
          return b.createdAt - a.createdAt;
        })
        .map((replies) => (
          <CommentBlockSingle replies={replies} key={replies.id} />
        ))}
      {Object.keys(threadPageData).length === 0 && (
        <div
          style={{
            border: "1px solid #cdcdcd",
            boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.01)",
            borderRadius: "8px",
            padding: "1em 2em 1em 0em",
            marginBlock: "2em",
            display: "flex",
            flexDirection: "row",
            justifyContent: "spaceBetween",
          }}
        >
          <div
            style={{
              flex: 1,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              padding: "1em",
            }}
          >
            <img
              src={TelescopeSVG}
              style={{ height: "4em", width: "4em" }}
              alt="TelescopeSVG"
            />
          </div>
          <div
            style={{
              flex: 6,
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-around",
            }}
          >
            <CustomText
              style={{
                color: "#747474",
                fontSize: "20px",
                fontWeight: "700",
                marginBlock: "0em",
              }}
            >
              No replies yet, but you can change that!
            </CustomText>

            <CustomText
              style={{
                color: "#555555",
                fontSize: "16px",
                fontWeight: "400",
                marginBlock: "0em",
              }}
            >
              {"Join the convo by replying, it's more fun that way :)"}
            </CustomText>
          </div>
        </div>
      )}
    </>
  );
}
