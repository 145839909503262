import { useEffect } from "react";
import { useNavigate, useParams, Outlet } from "react-router-dom";

import { getThreadById } from "../api/thread";

export default function CheckThreadExist() {
  const { workspaceId, threadId } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    // console.log(workspaceId, threadId);

    getThreadById(threadId)
      .then((res) => {
        // console.log(res.workspace.workspaceId, workspaceId);
        if (res.workspace.workspaceId !== workspaceId) {
          throw "err";
        }
      })
      .catch(() => navigate("/w/" + workspaceId));
  }, []);

  return <Outlet />;
}
