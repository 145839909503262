import { axiosGet, axiosPost, axiosPatch } from "../lib/apiUtil";

export const getWorkspacesByUserId = (userId) => {
  return axiosGet(`/user/${userId}/workspaces`);
  // axiosGet(`/user/${userId}/workspaces`).then((res) => console.log(res));
};

export const getWorkspaceById = (workspaceId) => {
  return axiosGet(`/workspace/${workspaceId}`);
};

export const createWorkspace = (workspaceData) => {
  return axiosPost(`/workspace`, workspaceData);
};

export const checkWorkspaceExists = (workspaceUsername) => {
  return axiosPost(`/workspace/exists`, { username: workspaceUsername });
};

// app.get("/user/:userId/workspaces", FBAuth, getUserWorkspaces);

// app.post("/workspace", FBAuth, createWorkspace);
// app.get("/workspace/:workspaceId", FBAuth, getWorkspace);
