import { useNavigate } from "react-router-dom";
import CustomText from "../CustomText";

import "./Search.css";

function SearchResults({
  searchResults,
  workspaceSearchResults,
  currentWorkspaceName,
  setPopupSearchOpen,
  searchFilters,
  query,
  workspaceQuery,
}) {
  const navigate = useNavigate();

  const workspaces = searchResults?.workspaces;
  const threads = searchResults?.threads;
  const postsInWorkspace = workspaceSearchResults;

  // console.log(searchResults);
  // console.log(workspaceSearchResults);
  // console.log(workspaceQuery?.length);
  // console.log(!!"");

  return (
    <div style={{ maxHeight: "50vh", overflowY: "scroll" }}>
      <>
        {searchFilters.includes("postsInWorkspace") && !!workspaceQuery && (
          <div
            style={{
              borderTop: "1px solid #cdcdcd",
              padding: "1em 0em",
            }}
          >
            <CustomText
              style={{
                color: "#555555",
                fontSize: "12px",
                fontWeight: "700",
                padding: "0em 1em 0.5em",
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
              }}
            >
              Posts in {currentWorkspaceName}
            </CustomText>
            {!!!postsInWorkspace ? (
              <div className="SearchResultLineNoHover">
                <CustomText
                  style={{
                    color: "#555555",
                    fontSize: "16px",
                    fontWeight: "400",
                    fontStyle: "italic",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                  }}
                >
                  Searching...
                </CustomText>
              </div>
            ) : (
              <>
                {postsInWorkspace.length === 0 ? (
                  <div className="SearchResultLineNoHover">
                    <CustomText
                      style={{
                        color: "#555555",
                        fontSize: "16px",
                        fontWeight: "400",
                        fontStyle: "italic",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                      }}
                    >
                      No posts found.
                    </CustomText>
                  </div>
                ) : (
                  postsInWorkspace.map((thread) => (
                    <div
                      key={thread.id}
                      className="SearchResultLine"
                      onClick={() => {
                        setPopupSearchOpen(false);
                        thread.topParent
                          ? navigate(
                              "/w/" +
                                thread.workspace.workspaceId +
                                "/t/" +
                                thread.topParent
                            )
                          : navigate(
                              "/w/" +
                                thread.workspace.workspaceId +
                                "/t/" +
                                thread.id
                            );
                      }}
                    >
                      <CustomText
                        style={{
                          color: "#555555",
                          fontSize: "16px",
                          fontWeight: "400",
                          cursor: "pointer",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          whiteSpace: "nowrap",
                        }}
                      >
                        {!!thread.title ? thread.title : thread.contentPlain}
                      </CustomText>
                    </div>
                  ))
                )}
              </>
            )}
          </div>
        )}
      </>
      <>
        {searchFilters.includes("workspaces") && !!query && (
          <div
            style={{
              borderTop: "1px solid #cdcdcd",
              padding: "1em 0em",
            }}
          >
            <CustomText
              style={{
                color: "#555555",
                fontSize: "12px",
                fontWeight: "700",
                padding: "0em 1em 0.5em",
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
              }}
            >
              Workspaces
            </CustomText>
            {!!!workspaces ? (
              <div className="SearchResultLineNoHover">
                <CustomText
                  style={{
                    color: "#555555",
                    fontSize: "16px",
                    fontWeight: "400",
                    fontStyle: "italic",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                  }}
                >
                  Searching...
                </CustomText>
              </div>
            ) : (
              <>
                {workspaces.length === 0 ? (
                  <div className="SearchResultLineNoHover">
                    <CustomText
                      style={{
                        color: "#555555",
                        fontSize: "16px",
                        fontWeight: "400",
                        fontStyle: "italic",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                      }}
                    >
                      No workspaces found.
                    </CustomText>
                  </div>
                ) : (
                  workspaces.map((workspace) => (
                    <div
                      key={workspace.id}
                      className="SearchResultLine"
                      onClick={() => {
                        setPopupSearchOpen(false);
                        navigate("/w/" + workspace.id);
                      }}
                    >
                      <CustomText
                        style={{
                          color: "#555555",
                          fontSize: "16px",
                          fontWeight: "400",
                          cursor: "pointer",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          whiteSpace: "nowrap",
                        }}
                      >
                        {workspace.workspaceName}
                      </CustomText>
                    </div>
                  ))
                )}
              </>
            )}
          </div>
        )}
      </>
      <>
        {searchFilters.includes("posts") && !!query && (
          <div
            style={{
              borderTop: "1px solid #cdcdcd",
              padding: "1em 0em",
            }}
          >
            <CustomText
              style={{
                color: "#555555",
                fontSize: "12px",
                fontWeight: "700",
                padding: "0em 1em 0.5em",
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
              }}
            >
              Posts
            </CustomText>
            {!!!threads ? (
              <div className="SearchResultLineNoHover">
                <CustomText
                  style={{
                    color: "#555555",
                    fontSize: "16px",
                    fontWeight: "400",
                    fontStyle: "italic",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                  }}
                >
                  Searching...
                </CustomText>
              </div>
            ) : (
              <>
                {threads.length === 0 ? (
                  <div className="SearchResultLineNoHover">
                    <CustomText
                      style={{
                        color: "#555555",
                        fontSize: "16px",
                        fontWeight: "400",
                        fontStyle: "italic",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                      }}
                    >
                      No posts found.
                    </CustomText>
                  </div>
                ) : (
                  threads.map((thread) => (
                    <div
                      key={thread.id}
                      className="SearchResultLine"
                      onClick={() => {
                        setPopupSearchOpen(false);
                        thread.topParent
                          ? navigate(
                              "/w/" +
                                thread.workspace.workspaceId +
                                "/t/" +
                                thread.topParent
                            )
                          : navigate(
                              "/w/" +
                                thread.workspace.workspaceId +
                                "/t/" +
                                thread.id
                            );
                      }}
                    >
                      <CustomText
                        style={{
                          color: "#7e7e7e",
                          fontSize: "12px",
                          fontWeight: "400",
                          cursor: "pointer",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          whiteSpace: "nowrap",
                        }}
                      >
                        {thread.workspace.workspaceName}
                      </CustomText>
                      <CustomText
                        style={{
                          color: "#555555",
                          fontSize: "16px",
                          fontWeight: "400",
                          cursor: "pointer",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          whiteSpace: "nowrap",
                        }}
                      >
                        {!!thread.title ? thread.title : thread.contentPlain}
                      </CustomText>
                    </div>
                  ))
                )}
              </>
            )}
          </div>
        )}
      </>
    </div>
  );
}

export default SearchResults;
