import { useNavigate, useParams } from "react-router-dom";
import { useRef, useState, useEffect } from "react";
import { useSelector } from "react-redux";

import TopBar from "../components/TopBar/TopBar";
import Body from "../components/Body/Body";
import CustomText from "../components/CustomText";
import NewThreadEditor from "../lexical/NewThreadEditor";
import NewThreadTitlePlainEditor from "../lexical/NewThreadTitlePlainEditor";
import CustomTextField from "../components/CustomTextField";
import CustomButton from "../components/CustomButton";
import CommentSVG from "../assets/Comment.svg";
import LoadSpinner from "../components/LoadSpinner";

import { createThread } from "../api/thread";

import { $getRoot } from "lexical";

function NewThreadScreen() {
  const { workspaceId } = useParams();
  const currentWorkspaceName = useSelector(
    (state) => state.workspace[workspaceId]?.workspaceName
  );

  const navigate = useNavigate();

  const editorRef = useRef(null);

  const [title, setTitle] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!localStorage.getItem("uid")) {
      navigate("/home");
    }
  }, []);

  return (
    <>
      <div
        style={{
          display: "flex",
          height: "calc(100vh - 80px)",
          overflowY: "scroll",
        }}
      >
        <div
          style={{
            flex: 1,
            padding: "2em 5% 0em 5%",
            borderRight: "1px solid #cdcdcd",
          }}
        >
          <div
            style={{ width: "3.5em" }}
            onClick={() => navigate("/w/" + workspaceId)}
          >
            <CustomText
              style={{
                color: "#747474",
                fontSize: "12px",
                marginBottom: "1em",
              }}
            >
              &lt; Back
            </CustomText>
          </div>

          <div
            style={{
              marginBottom: "1em",
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <CustomText
              style={{ color: "#747474", fontSize: "20px", fontWeight: "700" }}
            >
              Create a new thread
            </CustomText>
            <CustomButton
              disabled={loading}
              style={{
                backgroundColor: "#E4E4E4",
                width: "5em",
                height: "0.8em",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
              onClick={() => {
                if (title.length === 0) return;
                setLoading(true);
                const editorStateTextString = editorRef.current
                  .getEditorState()
                  .read(() => {
                    return $getRoot().getTextContent();
                  });
                createThread({
                  title: title,
                  content: JSON.stringify(editorRef.current.getEditorState()),
                  contentPlain: editorStateTextString,
                  workspace: {
                    workspaceId: workspaceId,
                    workspaceName: currentWorkspaceName,
                  },
                }).then((res) =>
                  navigate("/w/" + workspaceId + "/t/" + res.id)
                );
              }}
            >
              {loading ? (
                <LoadSpinner height={"1em"} />
              ) : (
                <CustomText style={{ color: "#747474" }}>
                  Post Thread
                </CustomText>
              )}
            </CustomButton>
          </div>
          <div style={{ marginBottom: "2em" }}>
            <CustomTextField
              onChange={(e) => setTitle(e.target.value)}
              value={title}
              placeholder="Title..."
            />
          </div>
          <div style={{ marginBottom: "2em" }}>
            <NewThreadEditor editorRef={editorRef} />
          </div>
        </div>

        <div
          style={{
            flex: 1,
            padding: "4em 4em 0em 4em",
            borderRight: "1px solid #cdcdcd",
          }}
        >
          <div
            style={{
              marginBottom: "1em",
            }}
          >
            {/* <CustomText
              style={{
                color: "#747474",
                fontSize: "20px",
                fontWeight: "700",
                marginBottom: "1.5em",
              }}
            >
              Similar threads:
            </CustomText>
            <>
              <div
                style={{
                  height: "5em",
                  width: "auto",
                  backgroundColor: "#FEFEFE",
                  border: "1px solid #CDCDCD",
                  borderRadius: "8px",
                  display: "flex",
                  padding: "0.5em 0em",
                }}
              >
                <div
                  style={{
                    flex: 1,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <CustomText
                    style={{
                      marginBlock: "0.5em",
                      color: "#7E7E7E",
                      fontSize: "20px",
                    }}
                  >
                    13.5k
                  </CustomText>
                </div>
                <div
                  style={{
                    flex: 7,
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-around",
                  }}
                >
                  <CustomText
                    style={{
                      color: "#747474",
                      fontSize: "16px",
                      fontWeight: "700",
                      marginBlock: "0em",
                    }}
                  >
                    Where do I get access to the Postman collection?
                  </CustomText>
                  <CustomText
                    style={{
                      color: "#555555",
                      fontSize: "12px",
                      fontWeight: "400",
                      marginBlock: "0em",
                    }}
                  >
                    That would probably expedite my development by 100x. On that
                    asdasdasda...
                  </CustomText>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    <img
                      src={CommentSVG}
                      style={{ height: "0.5em", width: "0.5em" }}
                      alt="CommentSVG"
                    />
                    <CustomText
                      style={{
                        color: "#949494",
                        fontSize: "12px",
                        marginLeft: "0.5em",
                      }}
                    >
                      156 comments
                    </CustomText>
                  </div>
                </div>
              </div>
            </> */}
          </div>
        </div>
      </div>
    </>
  );
}

export default NewThreadScreen;
