import { useNavigate, useParams } from "react-router-dom";

import CustomText from "../CustomText";
import UpvoteSVG from "../../assets/Upvote.svg";
import DownvoteSVG from "../../assets/Downvote.svg";
import NestSVG from "../../assets/Nest.svg";

import "./Body.css";

export default function BodyNoThreads({ profilePage = false }) {
  const navigate = useNavigate();
  const { workspaceId } = useParams();

  return (
    <>
      <div
        className="BodyThreadItem"
        onClick={() => {
          if (!profilePage) {
            navigate("/w/" + workspaceId + "/create");
          }
        }}
      >
        <div style={{ flex: 4, display: "flex" }}>
          <div
            style={{
              flex: 1,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              padding: "1em",
            }}
          >
            <img
              src={NestSVG}
              style={{ height: "4em", width: "4em" }}
              alt="NestSVG"
            />
          </div>
          <div
            style={{
              flex: 6,
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-around",
            }}
          >
            <CustomText
              style={{
                color: "#747474",
                fontSize: "20px",
                fontWeight: "700",
                marginBlock: "0em",
              }}
            >
              No posts yet... time to create one!
            </CustomText>
            {profilePage ? (
              <CustomText
                style={{
                  color: "#555555",
                  fontSize: "16px",
                  fontWeight: "400",
                  marginBlock: "0em",
                }}
              >
                {"Start a discussion in a community, it's more fun that way :)"}
              </CustomText>
            ) : (
              <CustomText
                style={{
                  color: "#555555",
                  fontSize: "16px",
                  fontWeight: "400",
                  marginBlock: "0em",
                }}
              >
                Click here or the [ + New Post ] to start a new thread.
              </CustomText>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
