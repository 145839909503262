import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import Skeleton from "@mui/material/Skeleton";

import TopBar from "../components/TopBar/TopBar";
import Body from "../components/Body/Body";
import CustomText from "../components/CustomText";
import UpvoteSVG from "../assets/Upvote.svg";
import DownvoteSVG from "../assets/Downvote.svg";
import CommentSVG from "../assets/Comment.svg";
import PFPSVG from "../assets/PFP.svg";

import PostBlock from "../components/PostBlock";
import CommentBlock from "../components/CommentBlock/CommentBlock";

import { getThreadById } from "../api/thread";
import { saveThreadToStoreAction } from "../redux/actions/threadActions";

function ThreadScreen() {
  const { threadId } = useParams();
  const dispatch = useDispatch();
  const threadData = useSelector((state) => state.thread[threadId]);

  useEffect(() => {
    getThreadById(threadId).then((res) => {
      dispatch(saveThreadToStoreAction(res));
    });
  }, [threadId]);

  return (
    <>
      <div
        style={{
          display: "flex",
          maxHeight: "calc(100vh - 80px)",
          overflowY: "scroll",
        }}
      >
        <div
          style={{
            flex: 3,
            // backgroundColor: "#DADADA",
            // minHeight: "92vh",
            padding: "0em 5%",
          }}
        >
          {!!threadData ? (
            <>
              <PostBlock threadData={threadData} />
              <CommentBlock threadPageData={threadData.threads} />
              <div style={{ height: "1em" }} />
            </>
          ) : (
            <Skeleton
              variant="rounded"
              width={"100%"}
              height={"10em"}
              animation="wave"
              style={{
                marginTop: "2em",
                background: "#FFFFFD",
                border: "1px solid #cdcdcd",
                boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.03)",
                borderRadius: "8px",
                // padding: "2em 2em 1em 0em",
                marginBlock: "2em",
              }}
            />
          )}
        </div>
        <div
          style={{
            // backgroundColor: "yellow",
            flex: 2,
          }}
        >
          {/* hello */}
        </div>
      </div>
    </>
  );
}

export default ThreadScreen;
