import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Grid from "@mui/material/Unstable_Grid2";

import TopBar from "../components/TopBar/TopBar";
import CustomText from "../components/CustomText";
import FlamingHeartSVG from "../assets/Fire.svg";

import { getWorkspaceById } from "../api/workspace";
import { saveWorkspaceToStoreAction } from "../redux/actions/workspaceActions";

import "../components/WorkspacesGrid/WorkspacesGrid.css";

const trendingWorkspaces = [
  {
    id: "startup",
    createdAt: 1666165533894,
    workspaceName: "Startup Blabber",
  },
  {
    id: "techternship",
    createdAt: 1666808838714,
    workspaceName: "Techternships",
  },
  {
    id: "collegeapp",
    createdAt: 1666747216340,
    workspaceName: "College Apps Blabber",
  },
  {
    id: "nba",
    createdAt: 1666808441759,
    workspaceName: "NBA",
  },
];

function DiscoverWorkspacesScreen() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const reduxWorkspaces = useSelector((state) => state.workspace);
  const selfUser = useSelector((state) => state.auth);

  useEffect(() => {
    //a UX optimization to load data before
    trendingWorkspaces.forEach((workspace) => {
      if (!!!reduxWorkspaces[workspace.id]) {
        // if it doesn't exist in store yet, load it
        getWorkspaceById(workspace.id).then((res) =>
          dispatch(saveWorkspaceToStoreAction(res))
        );
      }
    });
  }, []);

  return (
    <>
      <div
        style={{
          padding: "0em 5%",
          height: "calc(100vh - 80px)",
          overflowY: "scroll",
        }}
      >
        <CustomText
          style={{
            color: "#747474",
            fontSize: "20px",
            fontWeight: "700",
            marginTop: "1.5em",
          }}
        >
          Trending Communities
        </CustomText>
        <div style={{ margin: "2em 0em" }}>
          <Grid container spacing={6}>
            {trendingWorkspaces.map((workspace) => (
              <Grid
                xs={12}
                sm={6}
                md={4}
                onClick={() => navigate("/w/" + workspace.id)}
                key={workspace.workspaceName}
              >
                <div className="WorkspaceGridItem">
                  <div
                    style={{
                      display: "flex",
                      width: "100%",
                      alignItems: "center",
                      justifyContent: "flex-start",
                    }}
                  >
                    <img
                      src={FlamingHeartSVG}
                      style={{
                        height: "1em",
                        width: "1em",
                        marginRight: "1em",
                      }}
                      alt="FlamingHeartSVG"
                    />
                    <CustomText
                      style={{
                        color: "#747474",
                        fontSize: "16px",
                        fontWeight: "700",
                      }}
                    >
                      {workspace.workspaceName}
                    </CustomText>
                  </div>
                </div>
              </Grid>
            ))}
          </Grid>
        </div>
        {localStorage.getItem("uid") ? (
          <p
            className="DiscoverScreenText"
            onClick={() => navigate("/u/" + selfUser.username)}
          >
            Create your own community &#8594;
          </p>
        ) : (
          <p className="DiscoverScreenText" onClick={() => navigate("/signup")}>
            Join the conversation or create your own community &#8594;
          </p>
        )}
      </div>
    </>
  );
}

export default DiscoverWorkspacesScreen;
