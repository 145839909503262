import Grid from "@mui/material/Unstable_Grid2";
import { useNavigate } from "react-router-dom";

import ACMELogoSVG from "../../assets/ACMELogo.svg";
import CompassSVG from "../../assets/Compass.svg";
import ConstructionSVG from "../../assets/Construction.svg";
import CustomText from "../CustomText";
import AddPostSVG from "../../assets/AddPost.svg";

import "./WorkspacesGrid.css";

export default function WorkspacesGrid({
  managedWorkspaces,
  workspacesData,
  ownWorkspace = false,
}) {
  const navigate = useNavigate();

  let workspacesDataFiltered;
  if (!!workspacesData) {
    if (managedWorkspaces) {
      workspacesDataFiltered = Object.values(workspacesData)
        .filter((w) => w.isAdmin)
        .sort((a, b) => b.createdAt - a.createdAt);
    } else {
      workspacesDataFiltered = Object.values(workspacesData)
        .filter((w) => w.isMember)
        .sort((a, b) => b.createdAt - a.createdAt);
    }
  }

  return (
    <>
      <Grid container spacing={6}>
        {ownWorkspace ? (
          <>
            {managedWorkspaces ? (
              <Grid xs={4}>
                <div
                  className="WorkspaceGridItem"
                  onClick={() => navigate("/newworkspace")}
                  style={{ backgroundColor: "#fafafa" }}
                >
                  <div
                    style={{
                      display: "flex",
                      width: "100%",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <CustomText
                      style={{
                        color: "#747474",
                        fontSize: "16px",
                        fontWeight: "700",
                      }}
                    >
                      New Workspace
                    </CustomText>
                    <CustomText
                      style={{
                        color: "#747474",
                        // fontSize: "22px",
                        fontWeight: "700",
                      }}
                    >
                      +
                    </CustomText>
                  </div>
                </div>
              </Grid>
            ) : (
              <Grid xs={4}>
                <div
                  className="WorkspaceGridItem"
                  onClick={() => navigate("/discover")}
                  style={{ backgroundColor: "#fafafa" }}
                >
                  <div
                    style={{
                      display: "flex",
                      width: "100%",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <CustomText
                      style={{
                        color: "#747474",
                        fontSize: "16px",
                        fontWeight: "700",
                      }}
                    >
                      Discover Workspaces
                    </CustomText>
                    <img
                      src={CompassSVG}
                      style={{ height: "1em", width: "1em" }}
                      alt="CompassSVG"
                    />
                  </div>
                </div>
              </Grid>
            )}
          </>
        ) : (
          <>
            {(!!!workspacesDataFiltered ||
              workspacesDataFiltered.length === 0) && (
              <Grid xs={4}>
                <div className="WorkspaceGridItem">
                  <div
                    style={{
                      display: "flex",
                      width: "100%",
                      alignItems: "center",
                      justifyContent: "flex-start",
                    }}
                  >
                    <img
                      src={ConstructionSVG}
                      style={{
                        height: "1em",
                        width: "1em",
                        marginRight: "1em",
                      }}
                      alt="ConstructionSVG"
                    />
                    <CustomText
                      style={{
                        color: "#747474",
                        fontSize: "16px",
                        fontWeight: "700",
                      }}
                    >
                      Nothing here
                    </CustomText>
                  </div>
                </div>
              </Grid>
            )}
          </>
        )}
        <>
          <>
            {!!workspacesDataFiltered &&
              workspacesDataFiltered.map((workspace) => (
                <Grid xs={4} key={workspace.id}>
                  <div
                    className="WorkspaceGridItem"
                    onClick={() => navigate("/w/" + workspace.id)}
                  >
                    {/* <img
                  src={ACMELogoSVG}
                  className="WorkspaceGridItemImage"
                  alt="WorkspaceLogo"
                /> */}
                    <CustomText
                      style={{
                        color: "#747474",
                        fontSize: "16px",
                        fontWeight: "700",
                      }}
                    >
                      {workspace.workspaceName}
                    </CustomText>
                  </div>
                </Grid>
              ))}
          </>
        </>
      </Grid>
    </>
  );
}
