import TopBar from "../components/TopBar/TopBar";
import Body from "../components/Body/Body";
import CustomText from "../components/CustomText";
import Typewriter from "typewriter-effect";

import UpvoteBlueSVG from "../assets/UpvoteBlue.svg";
import DownvoteSVG from "../assets/Downvote.svg";
import MainScreenThread from "../components/MainScreen/MainScreenThread";
import MainScreenText from "../components/MainScreen/MainScreenText";

const data = {
  data1: [
    {
      title: "Intro to Christina Cacioppo @ Vanta?",
      post: "Hey all, can anyone make an intro to Christina? We'd be interested in partnering with them to build an integration.",
      workspace: "a16z network",
      upvotes: "531",
    },
    {
      title: "ARR metrics for raising Series A?",
      post: "Curious what the market looks like today to raise a Series A for a B2B SaaS company, especially a range for the ARR. Thanks!",
      workspace: "StartupChatter",
      upvotes: "10.5k",
    },
    {
      title: "Flordia Real Estate: Fastest Growing ZIP Codes",
      post: "The Census Bureau just released their most updated information on the population growth in Florida.",
      workspace: "Real Estate Investors",
      upvotes: "1.1k",
    },
  ],
  data2: [
    {
      title: "When will ClickUp add an integration with Intercom?",
      post: "Pretty much my only complaint with ClickUp is that there is no way to integrate it with Intercom.",
      workspace: "ClickUp Nerds",
      upvotes: "1.5k",
    },
    {
      title: "My new chicken parmesan recipe",
      post: "Hi all, many have asked so I have decided to share the recipe that has gotten so much attention.",
      workspace: "Learn Cooking",
      upvotes: "7.1k",
    },
    {
      title: "How I broke into VC: A Guide",
      post: "Howdy all! I was very fortunate to learn from so many friends when I was first entering VC, and I wanted to share that information with the community.",
      workspace: "Venture Capital",
      upvotes: "1.8k",
    },
  ],
  data3: [
    {
      title: "[PSET 4 #3a] How to calculate ε?",
      post: "I see that we should the Beer Lambert law to get the absorption, but where do we find the molar absorptivity constant?",
      workspace: "CHEM 108",
      upvotes: "203",
    },
    {
      title: "What legal entity for angel investing?",
      post: "Just beginning to invest, curious what legal entity people use to write their angel checks.",
      workspace: "Angel Investing",
      upvotes: "1.6k",
    },
    {
      title: "Announced: V3.0.5",
      post: "The new build for mobile kit is now released for public use!",
      workspace: "VR Build Kit",
      upvotes: "4.6k",
    },
  ],
  data4: [
    {
      title: "Convert Figma to React code?",
      post: "Does anyone have a recommendation for a tool to convert a Figma design to React code?",
      workspace: "Frontend Software",
      upvotes: "3.9k",
    },

    {
      title: "What do you think got you into Stanford?",
      post: "I was talking to an alum from my HS that went to Stanford, and she mentioned that all her friends at Stanford were ",
      workspace: "College Admissions",
      upvotes: "4.7k",
    },

    {
      title:
        "A VSCode extension for AI-generated documentation, works super well",
      post: "I've been trying this new extension for the last week and so far have only good things to say about it.",
      workspace: "VS Code",
      upvotes: "2.3k",
    },
  ],
};

function MainScreen() {
  return (
    <>
      {/* <TopBar /> */}

      <div
        style={{
          // backgroundColor: "#FFFFBB",
          height: "100vh",
          width: "100vw",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "space-around",
        }}
      >
        <MainScreenThread speed={20} data={data.data1} />
        <MainScreenThread speed={40} data={data.data2} direction={"right"} />
        <MainScreenText />
        <MainScreenThread speed={30} data={data.data3} />
        <MainScreenThread speed={35} data={data.data4} direction={"right"} />
      </div>
    </>
  );
}

export default MainScreen;
