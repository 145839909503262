export default function NotFound() {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <p>
        This is embarassing... this page does not exist. It's not you, it's us.
        :/
      </p>
    </div>
  );
}
