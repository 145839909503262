const dev = {
  firebase: {
    apiKey: "AIzaSyC8NBjKeJbQw8UyAv7C28_TWgsGePU6RII",
    authDomain: "blabberblabber-dev.firebaseapp.com",
    projectId: "blabberblabber-dev",
    storageBucket: "blabberblabber-dev.appspot.com",
    messagingSenderId: "833370484654",
    appId: "1:833370484654:web:698dc6631feb6aa1d1a23f",
    measurementId: "G-4RLW2SZ2QL",
  },
  baseURL: "https://us-central1-blabberblabber-dev.cloudfunctions.net/api",
};

const prod = {
  firebase: {
    apiKey: "AIzaSyAn4qucvsD_gMgepbRGVZjgMP6-Ka4qKG8",
    authDomain: "blabberblabber-prod.firebaseapp.com",
    projectId: "blabberblabber-prod",
    storageBucket: "blabberblabber-prod.appspot.com",
    messagingSenderId: "401089364664",
    appId: "1:401089364664:web:a68ef82bbe67e16b57aa4d",
    measurementId: "G-JLC6P17NG9",
  },
  baseURL: "https://us-central1-blabberblabber-prod.cloudfunctions.net/api",
};

export { dev, prod };
