import {
  LOGIN,
  LOGOUT,
  SIGNUP,
  UPDATE_USER,
  WORKSPACES_OF_USER,
  THREADS_OF_USER,
  ADD_USER,
  REDUCE_UNREAD,
} from "./types";

export const loginAction = (model) => async (dispatch) => {
  dispatch({ type: LOGIN, payload: model });
};

export const signupAction = (model) => async (dispatch) => {
  dispatch({ type: SIGNUP, payload: model });
};

export const logoutAction = () => async (dispatch) => {
  dispatch({ type: LOGOUT });
};

export const workspacesOfUserAction = (model) => async (dispatch) => {
  dispatch({ type: WORKSPACES_OF_USER, payload: model });
};

export const threadsOfUserAction = (model) => async (dispatch) => {
  dispatch({ type: THREADS_OF_USER, payload: model });
};

export const addUserAction = (model) => async (dispatch) => {
  dispatch({ type: ADD_USER, payload: model });
};

export const updateUserAction = (model) => async (dispatch) => {
  dispatch({ type: UPDATE_USER, payload: model });
};

export const reduceUnreadAction = (model) => async (dispatch) => {
  dispatch({ type: REDUCE_UNREAD, payload: model });
};
