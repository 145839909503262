import {
  LOGIN,
  LOGOUT,
  SIGNUP,
  ADD_USER,
  WORKSPACES_OF_USER,
  THREADS_OF_USER,
} from "../actions/types";
import {
  extractObjectFromObject,
  extractObjectFromArray,
} from "../../lib/transformationUtil";
import { connectFirestoreEmulator } from "firebase/firestore";

const INITIAL_STATE = {};

const userReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case LOGIN:
      return {
        ...state,
        [action.payload.uid]: action.payload.data,
      };

    case ADD_USER: //same as LOGIN FOR NOW, TODO: Separate auth and user
      // console.log({
      //   ...state,
      //   [action.payload.uid]: action.payload.data,
      // });
      return {
        ...state,
        [action.payload.uid]: action.payload.data,
      };

    case LOGOUT:
      return INITIAL_STATE;

    default:
      return state;
  }
};

export default userReducer;
