function TermsScreen() {
  return (
    <>
      Blabberblabber User Agreement Effective September 12, 2022. Last Revised
      August 12, 2022 This Blabberblabber User Agreement (“Terms”) applies to
      your access to and use of the websites, mobile apps, widgets, APIs,
      emails, and other online products and services (collectively, the
      “Services”) provided by Blabberblabber, Inc. (“Blabberblabber,” “we,”
      “us,” or “our”). Remember Blabberblabber is for fun and is intended to be
      a place for your entertainment, but we still need some basic rules. By
      accessing or using our Services, you agree to be bound by these Terms. If
      you do not agree to these Terms, you may not access or use our Services.
      1. Your Access to the Services No one under 13 is allowed to use or access
      the Services. We may offer additional Services that require you to be
      older to use them, so please read all notices and any Additional Terms
      carefully when you access the Services. By using the Services, you state
      that: You are at least 13 years old and over the minimum age required by
      the laws of your country of residence to access and use the Services; You
      can form a binding contract with Blabberblabber, or, if you are over 13
      but under the age of majority in your jurisdiction, that your legal
      guardian has reviewed and agrees to these Terms; You are not barred from
      using the Services under all applicable laws; and You have not been
      permanently suspended or removed from the Services. If you are accepting
      these Terms on behalf of another legal entity, including a business or
      government entity, you represent that you have full legal authority to
      bind such entity to these Terms. 2. Privacy Blabberblabber’s Privacy
      Policy explains how and why we collect, use, and share information about
      you when you access or use our Services. You understand that through your
      use of the Services, you consent to the collection and use of this
      information as set forth in the Privacy Policy. 3. Your Use of the
      Services Subject to your complete and ongoing compliance with these Terms,
      Blabberblabber grants you a personal, non-transferable, non-exclusive,
      revocable, limited license to: (a) install and use a copy of our mobile
      application associated with the Services that is obtained from a
      legitimate marketplace on a mobile device owned or controlled by you; and
      (b) access and use the Services. We reserve all rights not expressly
      granted to you by these Terms. Except and solely to the extent such a
      restriction is impermissible under applicable law, you may not, without
      our written agreement: license, sell, transfer, assign, distribute, host,
      or otherwise commercially exploit the Services or Content; modify, prepare
      derivative works of, disassemble, decompile, or reverse engineer any part
      of the Services or Content; or access the Services or Content in order to
      build a similar or competitive website, product, or service, except as
      permitted under the Blabberblabber API Terms of Use. We are always
      improving our Services. This means we may add or remove features,
      products, or functionalities; we will try to notify you beforehand, but
      that won’t always be possible. We reserve the right to modify, suspend, or
      discontinue the Services (in whole or in part) at any time, with or
      without notice to you. Any future release, update, or other addition to
      functionality of the Services will be subject to these Terms, which may be
      updated from time to time. You agree that we will not be liable to you or
      to any third party for any modification, suspension, or discontinuation of
      the Services or any part thereof. 4. Your Blabberblabber Account and
      Account Security To use certain features of our Services, you may be
      required to create a Blabberblabber account (an “Account”) and provide us
      with a username, password, and certain other information about yourself as
      set forth in the Privacy Policy. You are solely responsible for the
      information associated with your Account and anything that happens related
      to your Account. You must maintain the security of your Account and
      immediately notify Blabberblabber if you discover or suspect that someone
      has accessed your Account without your permission. We recommend that you
      use a strong password that is used only with your Account and enable
      two-factor authentication. You will not license, sell, or transfer your
      Account without our prior written approval. 5. Your Content The Services
      may contain information, text, links, graphics, photos, videos, audio,
      streams, or other materials (“Content”), including Content created with or
      submitted to the Services by you or through your Account (“Your Content”).
      We take no responsibility for and we do not expressly or implicitly
      endorse, support, or guarantee the completeness, truthfulness, accuracy,
      or reliability of any of Your Content. By submitting Your Content to the
      Services, you represent and warrant that you have all rights, power, and
      authority necessary to grant the rights to Your Content contained within
      these Terms. Because you alone are responsible for Your Content, you may
      expose yourself to liability if you post or share Content without all
      necessary rights. You retain any ownership rights you have in Your
      Content, but you grant Blabberblabber the following license to use that
      Content: When Your Content is created with or submitted to the Services,
      you grant us a worldwide, royalty-free, perpetual, irrevocable,
      non-exclusive, transferable, and sublicensable license to use, copy,
      modify, adapt, prepare derivative works of, distribute, store, perform,
      and display Your Content and any name, username, voice, or likeness
      provided in connection with Your Content in all media formats and channels
      now known or later developed anywhere in the world. This license includes
      the right for us to make Your Content available for syndication,
      broadcast, distribution, or publication by other companies, organizations,
      or individuals who partner with Blabberblabber. You also agree that we may
      remove metadata associated with Your Content, and you irrevocably waive
      any claims and assertions of moral rights or attribution with respect to
      Your Content. Any ideas, suggestions, and feedback about Blabberblabber or
      our Services that you provide to us are entirely voluntary, and you agree
      that Blabberblabber may use such ideas, suggestions, and feedback without
      compensation or obligation to you. Although we have no obligation to
      screen, edit, or monitor Your Content, we may, in our sole discretion,
      delete or remove Your Content at any time and for any reason, including
      for violating these Terms, violating our Content Policy, or if you
      otherwise create or are likely to create liability for us. 6. Third-Party
      Content, Advertisements, and Promotions The Services may contain links to
      third-party websites, products, or services, which may be posted by
      advertisers, our affiliates, our partners, or other users (“Third-Party
      Content”). Third-Party Content is not under our control, and we are not
      responsible for any third party’s websites, products, or services. Your
      use of Third-Party Content is at your own risk and you should make any
      investigation you feel necessary before proceeding with any transaction in
      connection with such Third-Party Content. The Services may also contain
      sponsored Third-Party Content or advertisements. The type, degree, and
      targeting of advertisements are subject to change, and you acknowledge and
      agree that we may place advertisements in connection with the display of
      any Content or information on the Services, including Your Content. If you
      choose to use the Services to conduct a promotion, including a contest or
      sweepstakes (“Promotion”), you alone are responsible for conducting the
      Promotion in compliance with all applicable laws and regulations,
      including but not limited to creating official rules, offer terms,
      eligibility requirements, and compliance with applicable laws, rules, and
      regulations which govern the Promotion (such as licenses, registrations,
      bonds, and regulatory approval). Your Promotion must state that the
      Promotion is not sponsored by, endorsed by, or associated with
      Blabberblabber, and the rules for your Promotion must require each entrant
      or participant to release Blabberblabber from any liability related to the
      Promotion. You acknowledge and agree that we will not assist you in any
      way with your promotion, and you agree to conduct your Promotion at your
      own risk. 7. Things You Cannot Do When using or accessing Blabberblabber,
      you must comply with these Terms and all applicable laws, rules, and
      regulations. Please review the Content Policy (and, where applicable, the
      Broadcasting Content Policy), which are incorporated by this reference
      into, and made a part of, these Terms and contain Blabberblabber’s rules
      about prohibited content and conduct. In addition to what is prohibited in
      the Content Policy, you may not do any of the following: Use the Services
      in any manner that could interfere with, disable, disrupt, overburden, or
      otherwise impair the Service; Gain access to (or attempt to gain access
      to) another user’s Account or any non-public portions of the Services,
      including the computer systems or networks connected to or used together
      with the Services; Upload, transmit, or distribute to or through the
      Services any viruses, worms, malicious code, or other software intended to
      interfere with the Services, including its security-related features; Use
      the Services to violate applicable law or infringe any person’s or
      entity's intellectual property rights or any other proprietary rights;
      Access, search, or collect data from the Services by any means (automated
      or otherwise) except as permitted in these Terms or in a separate
      agreement with Blabberblabber (we conditionally grant permission to crawl
      the Services in accordance with the parameters set forth in our robots.txt
      file, but scraping the Services without Blabberblabber’s prior consent is
      prohibited); or Use the Services in any manner that we reasonably believe
      to be an abuse of or fraud on Blabberblabber or any payment system. We
      encourage you to report content or conduct that you believe violates these
      Terms or our Content Policy. We also support the responsible reporting of
      security vulnerabilities. To report a security issue, please email
      security@Blabberblabber.com. 8. Moderators Moderating a
      BlabberCommunityblabber is an unofficial, voluntary position that may be
      available to users of the Services. We are not responsible for actions
      taken by the moderators. We reserve the right to revoke or limit a user’s
      ability to moderate at any time and for any reason or no reason, including
      for a breach of these Terms. If you choose to moderate a
      BlabberCommunityblabber: You agree to follow the Moderator Guidelines for
      Healthy Communities; You agree that when you receive reports related to a
      BlabberCommunityblabber you moderate, you will take appropriate action,
      which may include removing content that violates policy and/or promptly
      escalating to Blabberblabber for review; You are not, and may not
      represent that you are, authorized to act on behalf of Blabberblabber; You
      may not enter into any agreement with a third party on behalf of
      Blabberblabber, or any BlabberCommunityblabbers that you moderate, without
      our written approval; You may not perform moderation actions in return for
      any form of compensation, consideration, gift, or favor from third
      parties; If you have access to non-public information as a result of
      moderating a BlabberCommunityblabber, you will use such information only
      in connection with your performance as a moderator; and You may create and
      enforce rules for the BlabberCommunityblabbers you moderate, provided that
      such rules do not conflict with these Terms, the Content Policy, or the
      Moderator Guidelines for Healthy Communities. Blabberblabber reserves the
      right, but has no obligation, to overturn any action or decision of a
      moderator if Blabberblabber, in its sole discretion, believes that such
      action or decision is not in the interest of Blabberblabber or the
      Blabberblabber community. 9. Copyright, Trademark, the DMCA, and Takedowns
      Blabberblabber respects the intellectual property of others and requires
      that users of our Services do the same. We have a policy that includes the
      removal of any infringing material from the Services and for the
      termination, in appropriate circumstances, of users of our Services who
      are repeat infringers. If you believe that anything on our Services
      infringes a copyright or a trademark that you own or control, you may
      notify Blabberblabber’s Designated Agent by filling out our Copyright
      Report Form or Trademark Report Form, or by contacting: Copyright Agent
      Blabberblabber, Inc. 1455 Market Street, Suite 1600 San Francisco, CA
      94103 copyright@Blabberblabber.com Also, please note that if you knowingly
      misrepresent that any activity or material on our Service is infringing,
      you may be liable to Blabberblabber for certain costs and damages. If we
      remove Your Content in response to a copyright or trademark notice, we
      will notify you via Blabberblabber’s private messaging system. If you
      believe Your Content was wrongly removed due to a mistake or
      misidentification in a copyright notice, you can send a counter
      notification via our Copyright Counter Notice Form or to our Copyright
      Agent (contact information provided above). Please see 17 U.S.C. §
      512(g)(3) for the requirements of a proper counter notification. 10. Paid
      Services and Payment Information There are no fees for the use of many
      aspects of the Services. However, some services, including Blabberblabber
      Premium and Virtual Goods, may be available for purchase (“Paid
      Services”). In addition to these Terms, by purchasing or using
      Blabberblabber Premium or our Virtual Goods, you further agree to the
      Blabberblabber Premium and Virtual Goods Agreement. Blabberblabber may
      change the fees or benefits associated with the Paid Services from time to
      time with reasonable advance notice of material changes; provided,
      however, that no advance notice will be required for temporary promotions,
      including temporary reductions in the fees associated with the Paid
      Services. Notwithstanding the foregoing, any price changes or changes to
      your subscription plans will apply no earlier than 30 days following
      notice to you. You may submit your debit card, credit card, or other
      payment information (“Payment Information”) via our Services to purchase
      the Paid Services. We use third-party service providers to process your
      Payment Information. If you submit your Payment Information, you agree to
      pay all costs that you incur, and you give us permission to charge you
      when payment is due for an amount that includes these costs and any
      applicable taxes and fees. All transactions are final, and we do not
      refund or credit for partially used billing periods. 11. Intellectual
      Property The Services are owned and operated by Blabberblabber. The visual
      interfaces, graphics, design, compilation, information, data, computer
      code, products, services, trademarks, and all other elements of the
      Services (“Materials”) provided by Blabberblabber are protected by
      intellectual property and other laws. All Materials included in the
      Services are the property of Blabberblabber or its third-party licensors.
      You acknowledge and agree that you shall not acquire any ownership rights
      whatsoever by downloading Materials or by purchasing Blabberblabber
      Premium or Virtual Goods. Except as expressly authorized by
      Blabberblabber, and subject to Blabberblabber’s Brand Guidelines, you may
      not make use of the Materials. Blabberblabber reserves all rights to the
      Materials not granted expressly in these Terms. 12. Indemnity Except to
      the extent prohibited by law, you agree to defend, indemnify, and hold
      Blabberblabber, its affiliates, and their respective, directors, officers,
      employees, affiliates, agents, contractors, third-party service providers,
      and licensors (the “Blabberblabber Entities”) harmless from and against
      any claim or demand made by any third party, and any related liability,
      damage, loss, and expense (including costs and attorneys’ fees) due to,
      arising out of, or in connection with: (a) your use of the Services, (b)
      your violation of these Terms, (c) your violation of applicable laws or
      regulations, or (d) Your Content. We reserve the right to control the
      defense of any matter for which you are required to indemnify us, and you
      agree to cooperate with our defense of these claims. 13. Disclaimers and
      Limitation of Liability Nothing in these Terms will prejudice the
      statutory rights that you may have as a user of the Services. Some
      countries, states, provinces or other jurisdictions do not allow the
      exclusion of certain warranties or the limitation of liability as stated
      in this section, so the below terms may not fully apply to you. Instead,
      in such jurisdictions, the exclusions and limitations below shall apply
      only to the extent permitted by the laws of such jurisdictions. THE
      SERVICES ARE PROVIDED “AS IS” AND “AS AVAILABLE” WITHOUT REPRESENTATIONS,
      WARRANTIES, OR CONDITIONS OF ANY KIND, WHETHER EXPRESS, IMPLIED, LEGAL, OR
      STATUTORY, INCLUDING, BUT NOT LIMITED TO, IMPLIED WARRANTIES OF
      MERCHANTABILITY, TITLE, FITNESS FOR A PARTICULAR PURPOSE, AND
      NON-INFRINGEMENT. THE Blabberblabber ENTITIES DO NOT WARRANT THAT THE
      SERVICES ARE ACCURATE, COMPLETE, RELIABLE, CURRENT, OR ERROR FREE.
      Blabberblabber DOES NOT CONTROL, ENDORSE, OR TAKE RESPONSIBILITY FOR ANY
      CONTENT AVAILABLE ON OR LINKED TO THE SERVICES OR THE ACTIONS OF ANY THIRD
      PARTY OR USER, INCLUDING MODERATORS. WHILE Blabberblabber ATTEMPTS TO MAKE
      YOUR ACCESS TO AND USE OF OUR SERVICES SAFE, WE DO NOT REPRESENT OR
      WARRANT THAT OUR SERVICES OR SERVERS ARE FREE OF VIRUSES OR OTHER HARMFUL
      COMPONENTS. IN NO EVENT WILL ANY OF THE Blabberblabber ENTITIES BE LIABLE
      TO YOU FOR ANY INDIRECT, CONSEQUENTIAL, EXEMPLARY, INCIDENTAL, SPECIAL, OR
      PUNITIVE DAMAGES, OR LOST PROFITS ARISING FROM OR RELATING TO THESE TERMS
      OR THE SERVICES, INCLUDING THOSE ARISING FROM OR RELATING TO CONTENT MADE
      AVAILABLE ON THE SERVICES THAT IS ALLEGED TO BE DEFAMATORY, OFFENSIVE, OR
      ILLEGAL. ACCESS TO, AND USE OF, THE SERVICES IS AT YOUR OWN DISCRETION AND
      RISK, AND YOU WILL BE SOLELY RESPONSIBLE FOR ANY DAMAGE TO YOUR DEVICE OR
      COMPUTER SYSTEM, OR RESULTING LOSS OF DATA. IN NO EVENT WILL THE AGGREGATE
      LIABILITY OF THE Blabberblabber ENTITIES EXCEED THE GREATER OF ONE HUNDRED
      U.S. DOLLARS ($100) OR ANY AMOUNT YOU PAID Blabberblabber IN THE PREVIOUS
      SIX MONTHS FOR THE SERVICES GIVING RISE TO THE CLAIM. THE LIMITATIONS OF
      THIS SECTION WILL APPLY TO ANY THEORY OF LIABILITY, INCLUDING THOSE BASED
      ON WARRANTY, CONTRACT, STATUTE, TORT, NEGLIGENCE, STRICT LIABILITY, OR
      OTHERWISE, EVEN IF THE Blabberblabber ENTITIES HAVE BEEN ADVISED OF THE
      POSSIBILITY OF ANY SUCH DAMAGE, AND EVEN IF ANY REMEDY SET FORTH IN THESE
      TERMS IS FOUND TO HAVE FAILED ITS ESSENTIAL PURPOSE. 14. Governing Law and
      Venue We want you to enjoy Blabberblabber, so if you have an issue or
      dispute, you agree to raise it and try to resolve it with us informally.
      You can contact us with feedback and concerns here. To the fullest extent
      permitted by applicable law, any claims arising out of or relating to
      these Terms or the Services will be governed by the laws of the State of
      California, without regard to its conflict of laws rules; all disputes
      related to these Terms or the Services will be brought solely in the
      federal or state courts located in San Francisco, California, and you and
      Blabberblabber consent to personal jurisdiction in these courts. If you
      are a U.S. city, county, or state government entity, then this Section 14
      does not apply to you. If you are a U.S. federal government entity, any
      claims arising out of or relating to these Terms or the Services will be
      governed by the laws of the United States of America without regard to its
      conflict of laws rules. To the extent permitted by federal law, the laws
      of California (other than its conflict of law rules) will apply in the
      absence of applicable federal law. All disputes related to these Terms or
      the Services will be brought solely in the federal or state courts located
      in San Francisco, California. 15. Changes to these Terms We may make
      changes to these Terms from time to time. If we make changes, we will post
      the revised Terms and update the Effective Date above. If the changes, in
      our sole discretion, are material, we may also notify you by sending an
      email to the address associated with your Account (if you have chosen to
      provide an email address) or by otherwise providing you with notice
      through our Services. By continuing to access or use the Services on or
      after the Effective Date of the revised Terms, you agree to be bound by
      the revised Terms. If you do not agree to the revised Terms, you must stop
      accessing and using our Services before the changes become effective. 16.
      Additional Terms Because we offer a variety of Services, you may be asked
      to agree to additional terms, policies, guidelines, or rules before using
      a specific product or service offered by Blabberblabber (collectively,
      “Additional Terms”). All Additional Terms are incorporated by this
      reference into, and made a part of, these Terms, and to the extent any
      Additional Terms conflict with these Terms, the Additional Terms govern
      with respect to your use of the corresponding Services. If you use
      Blabberblabber Premium or Virtual Goods, you must also agree to the
      Blabberblabber Premium and Virtual Goods Agreement. If you use the
      self-service platform for advertising, you must also agree to our
      Blabberblabber Advertising Platform Terms. If you use our public API, you
      must also agree to our Blabberblabber API Terms of Use. If you use
      Blabberblabber Gifts, you must agree to the Blabberblabber Gifts User
      Agreement. If you use Blabberblabber Embeds, you must agree to the Embeds
      Terms of Use. 17. Termination You may terminate these Terms at any time
      and for any reason by deleting your Account and discontinuing use of all
      Services. If you stop using the Services without deactivating your
      Account, your Account may be deactivated due to prolonged inactivity. To
      the fullest extent permitted by applicable law, we may suspend or
      terminate your Account, moderator status, or ability to access or use the
      Services at any time for any or no reason, including for violating these
      Terms or our Content Policy. The following sections will survive any
      termination of these Terms or of your Account: 5 (Your Content), 7 (Things
      You Cannot Do), 12 (Indemnity), 13 (Disclaimers and Limitation of
      Liability), 14 (Governing Law and Venue), 17 (Termination), and 18
      (Miscellaneous). 18. Miscellaneous These Terms, together with the Privacy
      Policy and any other agreements expressly incorporated by reference into
      these Terms, constitute the entire agreement between you and us regarding
      your access to and use of the Services. Our failure to exercise or enforce
      any right or provision of these Terms will not operate as a waiver of such
      right or provision. If any part of these Terms is held to be invalid or
      unenforceable, the unenforceable part will be given effect to the greatest
      extent possible, and the remaining parts will remain in full force and
      effect. You may not assign or transfer any of your rights or obligations
      under these Terms without our consent. We may freely assign any of our
      rights and obligations under these Terms. These Terms are a
      legally-binding agreement between you and Blabberblabber, Inc. If you have
      any questions about these terms, please contact us. Blabberblabber, Inc.
      1455 Market Street, Suite 1600 San Francisco, CA 94103 United States
      Blabberblabber User Agreement if you live in the EEA, United Kingdom, or
      Switzerland Hello, Blabberblabberors and people of the Internet! This
      Blabberblabber User Agreement (“Terms”) applies to your access to and use
      of the websites, mobile apps, widgets, APIs, emails, and other online
      products and services (collectively, the “Services”) provided by
      Blabberblabber, Inc. (“Blabberblabber,” “we,” “us,” or “our”). Remember
      Blabberblabber is for fun and is intended to be a place for your
      entertainment, but we still need some basic rules. In order to use the
      Services, you must have accepted these Terms, which are: (a) presented to
      you when you create an Account; and (b) available at all times when you
      access the Services. If you don’t accept them, you may not access or use
      our Services. 1. Your Access to the Services No one under 13 is allowed to
      use or access the Services. We may offer additional Services that require
      you to be older to use them, so please read all notices and any Additional
      Terms carefully when you access the Services. By using the Services, you
      state that: You are at least 13 years old and over the minimum age
      required by the laws of your country of residence to access and use the
      Services; You can form a binding contract with Blabberblabber, or, if you
      are over 13 but under the age of majority in your jurisdiction, that your
      legal guardian has reviewed and agrees to these Terms; You are not barred
      from using the Services under all applicable laws; and You have not been
      permanently suspended or removed from the Services. If you are accepting
      these Terms on behalf of another legal entity, including a business or
      government entity, you represent that you have full legal authority to
      bind such entity to these Terms. 2. Privacy Blabberblabber’s Privacy
      Policy explains how and why we collect, use, and share information about
      you when you access or use our Services. You understand that through your
      use of the Services, you consent to the collection and use of this
      information as set forth in the Privacy Policy. 3. Your Use of the
      Services Subject to your complete and ongoing compliance with these Terms,
      Blabberblabber grants you a personal, non-transferable, non-exclusive,
      revocable, limited license to: (a) install and use a copy of our mobile
      application associated with the Services that is obtained from a
      legitimate marketplace on a mobile device owned or controlled by you; and
      (b) access and use the Services. We reserve all rights not expressly
      granted to you by these Terms. Except and solely to the extent such a
      restriction is impermissible under applicable law, you may not, without
      our written agreement: license, sell, transfer, assign, distribute, host,
      or otherwise commercially exploit the Services or Content; modify, prepare
      derivative works of, disassemble, decompile, or reverse engineer any part
      of the Services or Content; or access the Services or Content in order to
      build a similar or competitive website, product, or service, except as
      permitted under the Blabberblabber API Terms of Use. We do not guarantee
      that the Services will always be available or uninterrupted. We are always
      improving our Services. This means we may add or remove features,
      products, or functionalities; we will try to notify you beforehand, but
      that won’t always be possible. We reserve the right to modify, suspend, or
      discontinue the Services (in whole or in part) at any time, with or
      without notice to you. Any future release, update, or other addition to
      functionality of the Services will be subject to these Terms, which may be
      updated from time to time. You agree that we will not be liable to you or
      to any third party for any modification, suspension, or discontinuation of
      the Services or any part thereof. 4. Your Blabberblabber Account and
      Account Security To use certain features of our Services, you may be
      required to create a Blabberblabber account (an “Account”) and provide us
      with a username, password, and certain other information about yourself as
      set forth in the Privacy Policy. You are solely responsible for the
      information associated with your Account and anything that happens related
      to your Account. You must maintain the security of your Account and
      immediately notify Blabberblabber if you discover or suspect that someone
      has accessed your Account without your permission. We recommend that you
      use a strong password that is used only with your Account and enable
      two-factor authentication. You will not license, sell, or transfer your
      Account without our prior written approval. 5. Your Content The Services
      may contain information, text, links, graphics, photos, videos, audio,
      streams, or other materials (“Content”), including Content created with or
      submitted to the Services by you or through your Account (“Your Content”).
      We take no responsibility for and we do not expressly or implicitly
      endorse, support, or guarantee the completeness, truthfulness, accuracy,
      or reliability of any of Your Content. By submitting Your Content to the
      Services, you represent and warrant that you have all rights, power, and
      authority necessary to grant the rights to Your Content contained within
      these Terms. Because you alone are responsible for Your Content, you may
      expose yourself to liability if you post or share Content without all
      necessary rights. You retain any ownership rights you have in Your
      Content, but you grant Blabberblabber the following license to use that
      Content: When Your Content is created with or submitted to the Services,
      you grant us a worldwide, royalty-free, perpetual, irrevocable,
      non-exclusive, transferable, and sublicensable license to use, copy,
      modify, adapt, prepare derivative works of, distribute, store, perform,
      and display Your Content and any name, username, voice, or likeness
      provided in connection with Your Content in all media formats and channels
      now known or later developed anywhere in the world. This license includes
      the right for us to make Your Content available for syndication,
      broadcast, distribution, or publication by other companies, organizations,
      or individuals who partner with Blabberblabber. You also agree that we may
      remove metadata associated with Your Content, and you irrevocably waive
      any claims and assertions of moral rights or attribution with respect to
      Your Content. Any ideas, suggestions, and feedback about Blabberblabber or
      our Services that you provide to us are entirely voluntary, and you agree
      that Blabberblabber may use such ideas, suggestions, and feedback without
      compensation or obligation to you. Although we reserve the right to
      review, screen, edit, or monitor Your Content, we do not necessarily
      review all of it at the time it’s submitted to the Services. However, we
      may, in our sole discretion, delete or remove Your Content at any time and
      for any reason, including for violating these Terms, violating our Content
      Policy, or if you otherwise create or are likely to create liability for
      us. 6. Third-Party Content, Advertisements, and Promotions The Services
      may contain links to third-party websites, products, or services, which
      may be posted by advertisers, our affiliates, our partners, or other users
      (“Third-Party Content”). Third-Party Content is not under our control, and
      we are not responsible for any third party’s websites, products, or
      services. Your use of Third-Party Content is at your own risk, and you
      should make any investigation you feel necessary before proceeding with
      any transaction in connection with such Third-Party Content. The Services
      may also contain sponsored Third-Party Content or advertisements. The
      type, degree, and targeting of advertisements are subject to change, and
      you acknowledge and agree that we may place advertisements in connection
      with the display of any Content or information on the Services, including
      Your Content. If you choose to use the Services to conduct a promotion,
      including a contest or sweepstakes (“Promotion”), you alone are
      responsible for conducting the Promotion in compliance with all applicable
      laws and regulations, including but not limited to creating official
      rules, offer terms, eligibility requirements, and compliance with
      applicable laws, rules, and regulations which govern the Promotion (such
      as licenses, registrations, bonds, and regulatory approval). Your
      Promotion must state that the Promotion is not sponsored by, endorsed by,
      or associated with Blabberblabber, and the rules for your Promotion must
      require each entrant or participant to release Blabberblabber from any
      liability related to the Promotion. You acknowledge and agree that we will
      not assist you in any way with your promotion, and you agree to conduct
      your Promotion at your own risk. 7. Things You Cannot Do When using or
      accessing Blabberblabber, you must comply with these Terms and all
      applicable laws, rules, and regulations. Please review the Content Policy
      (and, where applicable, the Broadcasting Content Policy), which are
      incorporated by this reference into, and made a part of, these Terms and
      contain Blabberblabber’s rules about prohibited content and conduct. In
      addition to what is prohibited in the Content Policy, you may not do any
      of the following: Use the Services in any manner that could interfere
      with, disable, disrupt, overburden, or otherwise impair the Services; Gain
      access to (or attempt to gain access to) another user’s Account or any
      non-public portions of the Services, including the computer systems or
      networks connected to or used together with the Services; Upload,
      transmit, or distribute to or through the Services any viruses, worms,
      malicious code, or other software intended to interfere with the Services,
      including its security-related features; Use the Services to violate
      applicable law or infringe any person’s or entity's intellectual property
      rights or any other proprietary rights; Access, search, or collect data
      from the Services by any means (automated or otherwise) except as
      permitted in these Terms or in a separate agreement with Blabberblabber
      (we conditionally grant permission to crawl the Services in accordance
      with the parameters set forth in our robots.txt file, but scraping the
      Services without Blabberblabber’s prior consent is prohibited); or Use the
      Services in any manner that we reasonably believe to be an abuse of or
      fraud on Blabberblabber or any payment system. We encourage you to report
      content or conduct that you believe violates these Terms or our Content
      Policy. We also support the responsible reporting of security
      vulnerabilities. To report a security issue, please email
      security@Blabberblabber.com. 8. Moderators Moderating a
      BlabberCommunityblabber is an unofficial, voluntary position that may be
      available to users of the Services. We are not responsible for actions
      taken by the moderators. We reserve the right to revoke or limit a user’s
      ability to moderate at any time and for any reason or no reason, including
      for a breach of these Terms. If you choose to moderate a
      BlabberCommunityblabber: You agree to follow the Moderator Guidelines for
      Healthy Communities; You agree that when you receive reports related to a
      BlabberCommunityblabber you moderate, you will take appropriate action,
      which may include removing content that violates policy and/or promptly
      escalating to Blabberblabber for review; You are not, and may not
      represent that you are, authorized to act on behalf of Blabberblabber; You
      may not enter into any agreement with a third party on behalf of
      Blabberblabber, or any BlabberCommunityblabbers that you moderate, without
      our written approval; You may not perform moderation actions in return for
      any form of compensation, consideration, gift, or favor from third
      parties; If you have access to non-public information as a result of
      moderating a BlabberCommunityblabber, you will use such information only
      in connection with your performance as a moderator; and You may create and
      enforce rules for the BlabberCommunityblabbers you moderate, provided that
      such rules do not conflict with these Terms, the Content Policy, or the
      Moderator Guidelines for Healthy Communities. Blabberblabber reserves the
      right, but has no obligation, to overturn any action or decision of a
      moderator if Blabberblabber, in its sole discretion, believes that such
      action or decision is not in the interest of Blabberblabber or the
      Blabberblabber community. 9. Copyright, Trademark, the DMCA, and Takedowns
      Blabberblabber respects the intellectual property of others and requires
      that users of our Services do the same. We have a policy that includes the
      removal of any infringing material from the Services and for the
      termination, in appropriate circumstances, of users of our Services who
      are repeat infringers. If you believe that anything on our Services
      infringes a copyright or a trademark that you own or control, you may
      notify Blabberblabber’s Designated Agent by filling out our Copyright
      Report Form or Trademark Report Form, or by contacting: Copyright Agent
      Blabberblabber, Inc. 169 Madison Ave #2247 New York, NY 10016 United
      States copyright@Blabberblabber.com Also, please note that if you
      knowingly misrepresent that any activity or material on our Service is
      infringing, you may be liable to Blabberblabber for certain costs and
      damages. If we remove Your Content in response to a copyright or trademark
      notice, we will notify you via Blabberblabber’s private messaging system.
      If you believe Your Content was wrongly removed due to a mistake or
      misidentification in a copyright notice, you can send a counter
      notification via our Copyright Counter Notice Form or to our Copyright
      Agent (contact information provided above). Please see 17 U.S.C. §
      512(g)(3) for the requirements of a proper counter notification. 11.
      Intellectual Property The Services are owned and operated by
      Blabberblabber. The visual interfaces, graphics, design, compilation,
      information, data, computer code, products, services, trademarks, and all
      other elements of the Services (“Materials”) provided by Blabberblabber
      are protected by intellectual property and other laws. All Materials
      included in the Services are the property of Blabberblabber or its
      third-party licensors. You acknowledge and agree that you shall not
      acquire any ownership rights whatsoever by downloading Materials or by
      purchasing Blabberblabber Premium or Virtual Goods. Except as expressly
      authorized by Blabberblabber, and subject to Blabberblabber’s Brand
      Guidelines, you may not make use of the Materials. Blabberblabber reserves
      all rights to the Materials not granted expressly in these Terms. 12.
      Indemnity Except to the extent prohibited by law, you agree to defend,
      indemnify, and hold Blabberblabber, its affiliates, and their respective
      directors, officers, employees, affiliates, agents, contractors,
      third-party service providers, and licensors (the “Blabberblabber
      Entities”) harmless from and against any claim or demand made by any third
      party, and any related liability, damage, loss, and expense (including
      costs and attorneys’ fees) due to, arising out of, or in connection with:
      (a) your use of the Services, (b) your violation of these Terms, (c) your
      violation of applicable laws or regulations, or (d) Your Content. We
      reserve the right to control the defense of any matter for which you are
      required to indemnify us, and you agree to cooperate with our defense of
      these claims. 13. Limitation of Liability By using the Services you agree
      that the Blabberblabber Entities’ liability is limited to the maximum
      extent permissible in your country of residence. Liability will be limited
      to foreseeable damages arising due to a breach of material contractual
      obligations typical for this type of contract. Blabberblabber isn’t liable
      for damages that result from a non-material breach of any other applicable
      duty of care. This limitation of liability will not apply to any statutory
      liability that cannot be limited, to liability for death or personal
      injury caused by our negligence or willful misconduct, or if and to
      exclude our responsibility for something we have specifically promised to
      you. 14. Changes to these Terms We may make changes to these Terms from
      time to time. If we make changes, we will post the revised Terms and
      update the Effective Date above. If the changes, in our reasonable
      discretion, are material, we will notify you by sending an email to the
      address associated with your Account (if you have chosen to provide an
      email address) or by otherwise providing you with notice through our
      Services at least 30 days before the date they become effective. By
      continuing to access or use the Services on or after the Effective Date of
      the revised Terms, you agree to be bound by the revised Terms. If you do
      not agree to the revised Terms, you must stop accessing and using our
      Services before the changes become effective. 15. Additional Terms Because
      we offer a variety of Services, you may be asked to agree to additional
      terms, policies, guidelines, or rules before using a specific product or
      service offered by Blabberblabber (collectively, “Additional Terms”). All
      Additional Terms are incorporated by this reference into, and made a part
      of, these Terms, and to the extent any Additional Terms conflict with
      these Terms, the Additional Terms govern with respect to your use of the
      corresponding Services. If you use Blabberblabber Premium or Virtual
      Goods, you must also agree to the Blabberblabber Premium and Virtual Goods
      Agreement. If you use the self-service platform for advertising, you must
      also agree to our Blabberblabber Advertising Platform Terms. If you use
      our public API, you must also agree to our Blabberblabber API Terms of
      Use. If you use Blabberblabber Gifts, you must agree to the Blabberblabber
      Gifts User Agreement. If you use Blabberblabber Embeds, you must agree to
      the Embeds Terms of Use. 16. Termination You may terminate these Terms at
      any time and for any reason by deleting your Account and discontinuing use
      of all Services. If you stop using the Services without deactivating your
      Account, your Account may be deactivated due to prolonged inactivity. To
      the fullest extent permitted by applicable law, we may suspend or
      terminate your Account, moderator status, or ability to access or use the
      Services at any time for any or no reason, including for violating these
      Terms or our Content Policy. The following sections will survive any
      termination of these Terms or of your Account: 5 (Your Content), 7 (Things
      You Cannot Do), 12 (Indemnity), 13 (Limitation of Liability), 16
      (Termination), and 17 (Miscellaneous). 17. Miscellaneous These Terms,
      together with the Privacy Policy and any other agreements expressly
      incorporated by reference into these Terms, constitute the entire
      agreement between you and us regarding your access to and use of the
      Services. Our failure to exercise or enforce any right or provision of
      these Terms will not operate as a waiver of such right or provision. If
      any part of these Terms is held to be invalid or unenforceable, the
      unenforceable part will be given effect to the greatest extent possible,
      and the remaining parts will remain in full force and effect. You may not
      assign or transfer any rights or obligations under these Terms without
      Blabberblabber’s prior written consent. Blabberblabber may, without
      restriction, assign any of our rights and obligations under these Terms,
      at its sole discretion, with 30 days’ prior notice. Your right to
      terminate these Terms at any time pursuant to Section 16 remains
      unaffected. These Terms are a legally-binding agreement between you and
      Blabberblabber, Inc. If you have any questions about these terms, please
      contact us. 18. Contact Details Blabberblabber, Inc. 169 Madison Ave #2247
      New York, NY 10016 United States
    </>
  );
}

export default TermsScreen;
