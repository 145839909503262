import { useState, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { $getRoot } from "lexical";

import PFPSVG from "../../assets/PFP.svg";
import CustomText from "../CustomText";
import CustomButton from "../CustomButton";
import CommentStatic from "../../lexical/CommentStatic";
import PopupSignup from "../PopupSignup";
import NewThreadEditor from "../../lexical/NewThreadEditor";
import LoadSpinner from "../LoadSpinner";
import {
  timeSince,
  countUpvotesMinusDownvotes,
} from "../../lib/transformationUtil";

import { createThreadReply } from "../../api/thread";

import "./CommentBlock.css";
import { handleVote } from "../../lib/voteUtil";
import { useDispatch } from "react-redux";
import { addReplyToStoreAction } from "../../redux/actions/threadActions";

const styles = {
  reply: {
    commentBlock: {
      display: "flex",
      flexDirection: "row",
      padding: "0em 1em",
    },
    replyText: {
      color: "#555555",
      fontSize: 16,
    },
    replyButtonText: {
      color: "#555555",
      fontSize: 12,
      fontWeight: "bold",
      marginRight: "1em",
      cursor: "pointer",
    },
    replyLeftColumn: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },

    verticalLine: {
      marginTop: "0.5em",
      width: "0px",
      height: "100%",
      border: "0.5px solid #E0E0E0",
    },
    contentBox: {
      marginLeft: "1em",
      flex: 1,
    },
    replyUserText: {
      margin: "0",
      fontSize: 12,
      color: "#7E7E7E",
    },
    upvoteReplyBar: {
      display: "flex",
      flexDirection: "row",
    },
  },
  subreply: {
    subreplyCommentBlock: {
      display: "flex",
      flexDirection: "row",
      paddingTop: "1em",
    },
  },
};

export default function CommentBlockSingleSub({ subreplies, setShowReply }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [popupOpen, setPopupOpen] = useState(false);

  const currentThreadData = useSelector((state) => state.thread[subreplies.id]);

  return (
    <>
      <PopupSignup
        popupOpen={popupOpen}
        setPopupOpen={setPopupOpen}
        navigate={navigate}
      />
      <div style={styles.subreply.subreplyCommentBlock} key={subreplies.id}>
        {/* // Subreply Profile Picture and Line */}
        <div style={styles.reply.replyLeftColumn}>
          <div onClick={() => navigate("/u/" + subreplies.poster.username)}>
            <img
              src={`data:image/svg+xml;utf8,${encodeURIComponent(
                currentThreadData.poster.pfp
              )}`}
              style={{
                height: "1.5em",
                width: "1.5em",
              }}
              alt="PFPSVG"
            />
          </div>
          <div style={styles.reply.verticalLine} />
        </div>
        {/* // Subreply Content (without pfp & vline) */}
        <div style={styles.reply.contentBox}>
          {/* 11pt Nancy 22d ago */}
          <CustomText style={styles.reply.replyUserText}>
            <span
              style={{
                color:
                  localStorage.getItem("uid") in subreplies.upvotes
                    ? "#5891E5"
                    : "#7E7E7E",
              }}
            >
              {countUpvotesMinusDownvotes(subreplies.upvotes)}
              pts
            </span>{" "}
            •{" "}
            <span
              onClick={() => navigate("/u/" + subreplies.poster.username)}
              style={{ cursor: "pointer" }}
            >
              {currentThreadData.poster.usernameStyled}
            </span>{" "}
            • {timeSince(currentThreadData.createdAt)} ago
          </CustomText>
          {/* // Reply text here */}
          <div style={styles.reply.replyText}>
            <CommentStatic initialEditorState={currentThreadData.content} />
          </div>
          {/* // Upvote Reply */}
          <div style={styles.reply.upvoteReplyBar}>
            <a
              onClick={() =>
                handleVote(
                  currentThreadData.id,
                  localStorage.getItem("uid"),
                  "upvote",
                  dispatch,
                  setPopupOpen,
                  null
                )
              }
            >
              <CustomText
                style={{
                  ...styles.reply.replyButtonText,
                  color: subreplies.upvotes[localStorage.getItem("uid")]
                    ? "#5891E5"
                    : "#555555",
                }}
              >
                Upvote
              </CustomText>
            </a>
            <a
              onClick={() =>
                handleVote(
                  currentThreadData.id,
                  localStorage.getItem("uid"),
                  "downvote",
                  dispatch,
                  setPopupOpen,
                  null
                )
              }
            >
              <CustomText
                style={{
                  ...styles.reply.replyButtonText,
                  color:
                    subreplies.upvotes[localStorage.getItem("uid")] === false
                      ? "#5891E5"
                      : "#555555",
                }}
              >
                Downvote
              </CustomText>
            </a>
            <a
              onClick={() => {
                if (localStorage.getItem("uid")) {
                  setShowReply(true);
                } else {
                  setPopupOpen(true);
                }
              }}
            >
              <CustomText style={styles.reply.replyButtonText}>
                Reply
              </CustomText>
            </a>
          </div>
        </div>
      </div>
    </>
  );
}
