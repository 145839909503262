import { axiosGet, axiosPost, axiosPatch } from "../lib/apiUtil";

export const searchByQuery = (query) => {
  return axiosPost(`/search`, { search: query });
};

export const searchInWorkspaceByQuery = (workspaceId, query) => {
  // console.log(`/workspace/${workspaceId}/search`, { search: query });
  return axiosPost(`/workspace/${workspaceId}/search`, { search: query });
};
