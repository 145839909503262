import { voteThreadById } from "../api/thread";
import {
  saveUpvoteToStoreAction,
  saveDownvoteToStoreAction,
} from "../redux/actions/threadActions";

import { voteThreadInWorkspaceAction } from "../redux/actions/workspaceActions";

// const handleVote = (threadId, uid, voteType, dispatch) => {
//   voteThreadById(threadId, uid, voteType).then((res) => {
//     if (voteType === "upvote") {
//       dispatch(
//         saveUpvoteToStoreAction({
//           threadId: res.id,
//           userId: uid,
//         })
//       );
//     } else {
//       dispatch(
//         saveDownvoteToStoreAction({
//           threadId: res.id,
//           userId: uid,
//         })
//       );
//     }
//   });
// };
const handleVote = (
  threadId,
  uid,
  voteType,
  dispatch,
  setPopupOpen,
  workspaceId = null
) => {
  if (!!!localStorage.getItem("uid")) {
    setPopupOpen(true);
    return;
  }

  if (voteType === "upvote") {
    dispatch(
      saveUpvoteToStoreAction({
        threadId: threadId,
        userId: uid,
      })
    );
    if (workspaceId) {
      //only if top level thread does it have workspaceId
      dispatch(
        voteThreadInWorkspaceAction({
          workspaceId: workspaceId,
          threadId: threadId,
          userId: uid,
          type: "upvote",
        })
      );
    }
  } else {
    // console.log("downvoting");
    dispatch(
      saveDownvoteToStoreAction({
        threadId: threadId,
        userId: uid,
      })
    );
    if (workspaceId) {
      //only if top level thread does it have workspaceId
      dispatch(
        voteThreadInWorkspaceAction({
          workspaceId: workspaceId,
          threadId: threadId,
          userId: uid,
          type: "downvote",
        })
      );
    }
  }
  // console.log(threadId, uid, voteType);
  voteThreadById(threadId, uid, voteType);
};

export { handleVote };
