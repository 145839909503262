import { useEffect } from "react";
import { useNavigate, useParams, Outlet } from "react-router-dom";

import { getProfileByUsername } from "../api/user";

export default function CheckUserExist() {
  const { userId } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    getProfileByUsername(userId).catch(() => navigate("/home"));
  }, []);

  return <Outlet />;
}
