import TopBar from "../components/TopBar/TopBar";
import Body from "../components/Body/Body";

function HomeScreen() {
  return (
    <>
      {/* <TopBar /> */}
      <Body />
    </>
  );
}

export default HomeScreen;
