import { useState, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { $getRoot } from "lexical";

import UpvoteSVG from "../assets/Upvote.svg";
import UpvoteBlueSVG from "../assets/UpvoteBlue.svg";
import DownvoteSVG from "../assets/Downvote.svg";
import DownvoteBlueSVG from "../assets/DownvoteBlue.svg";
import PFPSVG from "../assets/PFP.svg";

import CustomButton from "./CustomButton";
import PopupSignup from "./PopupSignup";
import LoadSpinner from "./LoadSpinner";
import CommentStatic from "../lexical/CommentStatic";
import NewThreadEditor from "../lexical/NewThreadEditor";
import CustomText from "./CustomText";
import {
  timeSince,
  countUpvotesMinusDownvotes,
} from "../lib/transformationUtil";

import { createThreadReply } from "../api/thread";

import { useDispatch } from "react-redux";
import { addReplyToStoreAction } from "../redux/actions/threadActions";

import { handleVote } from "../lib/voteUtil";

export default function PostBlock({ threadData }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [popupOpen, setPopupOpen] = useState(false);

  const { threadId, workspaceId } = useParams();

  const currentWorkspaceName = useSelector(
    (state) => state.workspace[workspaceId]?.workspaceName
  );

  const [showReply, setShowReply] = useState(false);
  const [loading, setLoading] = useState(false);
  const editorRef = useRef(null);

  return (
    <>
      <PopupSignup
        popupOpen={popupOpen}
        setPopupOpen={setPopupOpen}
        navigate={navigate}
      />

      <div
        // className="BodySortBlock"
        style={{
          background: "#FFFFFD",
          border: "1px solid #cdcdcd",
          boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.03)",
          borderRadius: "8px",
          padding: "2em 2em 1em 0em",
          marginBlock: "2em",
          display: "flex",
          //   alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <div
          style={{
            flex: 1,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "flex-start",
            padding: "1em",
          }}
        >
          <div
            onClick={() =>
              handleVote(
                threadData.id,
                localStorage.getItem("uid"),
                "upvote",
                dispatch,
                setPopupOpen,
                threadData.workspace.workspaceId
              )
            }
            style={{ cursor: "pointer" }}
          >
            {threadData.upvotes[localStorage.getItem("uid")] ? (
              <img
                src={UpvoteBlueSVG}
                style={{ height: "1em", width: "1em" }}
                alt="UpvoteBlueSVG"
              />
            ) : (
              <img
                src={UpvoteSVG}
                style={{ height: "1em", width: "1em" }}
                alt="UpvoteSVG"
              />
            )}
          </div>
          <CustomText
            style={{
              marginBlock: "0.5em",
              color:
                localStorage.getItem("uid") in threadData.upvotes
                  ? "#5891E5"
                  : "#7E7E7E",
              fontSize: "20px",
            }}
          >
            {countUpvotesMinusDownvotes(threadData.upvotes)}
          </CustomText>
          <div
            onClick={() =>
              handleVote(
                threadData.id,
                localStorage.getItem("uid"),
                "downvote",
                dispatch,
                setPopupOpen,
                threadData.workspace.workspaceId
              )
            }
            style={{ cursor: "pointer" }}
          >
            {threadData.upvotes[localStorage.getItem("uid")] === false ? (
              <img
                src={DownvoteBlueSVG}
                style={{ height: "1em", width: "1em" }}
                alt="DownvoteBlueSVG"
              />
            ) : (
              <img
                src={DownvoteSVG}
                style={{ height: "1em", width: "1em" }}
                alt="DownvoteSVG"
              />
            )}
          </div>
        </div>
        <div
          style={{
            flex: 7,
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-around",
          }}
        >
          <CustomText
            style={{
              color: "#747474",
              fontSize: "20px",
              fontWeight: "700",
              marginBlock: "0em",
            }}
          >
            {threadData.title}
          </CustomText>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              marginTop: "1em",
              cursor: "pointer",
            }}
            onClick={() => navigate("/u/" + threadData.poster.username)}
          >
            <img
              src={`data:image/svg+xml;utf8,${encodeURIComponent(
                threadData.poster.pfp
              )}`}
              style={{
                height: "1.5em",
                width: "1.5em",
                marginRight: "1em",
              }}
              alt="PFPSVG"
            />
            <CustomText
              style={{
                fontWeight: "400",
                fontSize: "14px",
                color: "#7E7E7E",
              }}
            >
              {threadData.poster.usernameStyled} •{" "}
              {timeSince(threadData.createdAt)} ago
            </CustomText>
          </div>
          <div style={{ margin: "1em 0em 0em 0em", color: "#555555" }}>
            <CommentStatic initialEditorState={threadData.content} />
          </div>
          <div
            style={{ display: "flex", flexDirection: "row", marginTop: "1em" }}
          >
            <a
              onClick={() =>
                handleVote(
                  threadData.id,
                  localStorage.getItem("uid"),
                  "upvote",
                  dispatch,
                  setPopupOpen,
                  threadData.workspace.workspaceId
                )
              }
            >
              <CustomText
                style={{
                  color: threadData.upvotes[localStorage.getItem("uid")]
                    ? "#5891E5"
                    : "#555555",
                  fontSize: 12,
                  fontWeight: "bold",
                  marginRight: "1em",
                  cursor: "pointer",
                }}
              >
                Upvote
              </CustomText>
            </a>
            <a
              onClick={() =>
                handleVote(
                  threadData.id,
                  localStorage.getItem("uid"),
                  "downvote",
                  dispatch,
                  setPopupOpen,
                  threadData.workspace.workspaceId
                )
              }
            >
              <CustomText
                style={{
                  color:
                    threadData.upvotes[localStorage.getItem("uid")] === false
                      ? "#5891E5"
                      : "#555555",
                  fontSize: 12,
                  fontWeight: "bold",
                  marginRight: "1em",
                  cursor: "pointer",
                }}
              >
                Downvote
              </CustomText>
            </a>
            <a
              onClick={() => {
                if (localStorage.getItem("uid")) {
                  setShowReply(true);
                } else {
                  setPopupOpen(true);
                }
              }}
            >
              <CustomText
                style={{
                  color: "#555555",
                  fontSize: 12,
                  fontWeight: "bold",
                  marginRight: "1em",
                  cursor: "pointer",
                }}
              >
                Reply
              </CustomText>
            </a>
          </div>
        </div>
      </div>
      {showReply && (
        <>
          <NewThreadEditor editorRef={editorRef} />
          <div
            style={{
              marginTop: "1em",
              display: "flex",
              flexDirection: "row",
            }}
          >
            <CustomButton
              disabled={loading}
              style={{
                backgroundColor: "#E4E4E4",
                width: "6em",
                height: "0.8em",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
              onClick={() => {
                setLoading(true);
                const editorStateTextString = editorRef.current
                  .getEditorState()
                  .read(() => {
                    return $getRoot().getTextContent();
                  });
                createThreadReply(threadData.id, {
                  content: JSON.stringify(editorRef.current.getEditorState()),
                  contentPlain: editorStateTextString,
                  parent: threadData.id,
                  topParent: threadId,
                  workspace: {
                    workspaceId: threadData.workspace.workspaceId,
                    workspaceName: currentWorkspaceName,
                  },
                }).then((res) => {
                  // console.log(res);
                  dispatch(addReplyToStoreAction(res));
                  setLoading(false);
                  setShowReply(false);
                });
              }}
            >
              {loading ? (
                <LoadSpinner height={"1em"} />
              ) : (
                <CustomText style={{ color: "#747474" }}>
                  Post Comment
                </CustomText>
              )}
            </CustomButton>
            <CustomButton
              disabled={false}
              style={{
                backgroundColor: "#FFFFFF",
                width: "1em",
                height: "0.8em",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginLeft: "1em",
              }}
              onClick={() => setShowReply(false)}
            >
              <CustomText style={{ color: "#747474" }}>Cancel</CustomText>
            </CustomButton>
          </div>
        </>
      )}
    </>
  );
}
