import CustomText from "../CustomText";
import Marquee from "react-fast-marquee";

import UpvoteBlueSVG from "../../assets/UpvoteBlue.svg";
import DownvoteSVG from "../../assets/Downvote.svg";

export default function MainScreenThread({ speed, data, direction }) {
  return (
    <>
      <Marquee speed={speed} pauseOnHover={true} direction={direction}>
        {data.map((thread) => (
          <div
            style={{
              backgroundColor: "#fefef9",
              // height: "3.5em",
              width: "40em",
              padding: "1em 1em",

              display: "flex",
              border: "1px solid #cdcdcd",
              borderRadius: "8px",
              margin: "0em 1em",

              // position: "absolute",
              // top: thread.top + "vh",
              // left: thread.left + "vw",
            }}
          >
            <div
              style={{
                flex: "1.5",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                // backgroundColor: "yellow",
              }}
            >
              <img
                src={UpvoteBlueSVG}
                style={{ height: "1em", width: "1em" }}
                alt="UpvoteBlueSVG"
              />

              <CustomText
                style={{
                  marginBlock: "0.5em",
                  color: "#5891E5",
                  fontSize: "20px",
                }}
              >
                {thread.upvotes}
              </CustomText>

              <img
                src={DownvoteSVG}
                style={{ height: "1em", width: "1em" }}
                alt="DownvoteSVG"
              />
            </div>
            <div
              style={{
                flex: "7",
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                justifyContent: "center",
                padding: "0em 1em",
                // backgroundColor: "blue",
              }}
            >
              <CustomText
                style={{
                  color: "#747474",
                  fontSize: "20px",
                  fontWeight: "700",
                  marginBlock: "0em",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                  maxWidth: "25em",
                }}
              >
                {thread.title}
              </CustomText>
              <CustomText
                style={{
                  color: "#555555",
                  fontSize: "16px",
                  fontWeight: "400",
                  marginBlock: "0em",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                  maxWidth: "25em",
                  marginTop: "1em",
                }}
              >
                {thread.post}
              </CustomText>
            </div>
            <div
              style={{
                flex: "1.5",
                borderLeft: "1px solid #D9D9D9",
                // backgroundColor: "red",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "column",
                minWidth: 0,
                padding: "0em 2em",
              }}
            >
              <CustomText
                style={{
                  color: "#555555",
                  fontSize: "12px",
                  fontWeight: "400",
                }}
              >
                {thread.workspace}
              </CustomText>
            </div>
          </div>
        ))}
      </Marquee>
    </>
  );
}
