import { useState, useRef, useEffect } from "react";
import useDebounce from "../lib/useDebounce";

import { Formik } from "formik";
import CircularProgress from "@mui/material/CircularProgress";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";

import TopBar from "../components/TopBar/TopBar";
import CustomText from "../components/CustomText";
import CustomTextField from "../components/CustomTextField";
import CustomButton from "../components/CustomButton";
import LoadSpinner from "../components/LoadSpinner";

import { checkWorkspaceExists, createWorkspace } from "../api/workspace";

const nameSchema = Yup.object().shape({
  name: Yup.string()
    // .min(, "Must be at least 8 characters")
    .required("Name is required")
    .min(3, "Needs 3 characters minimum")
    .matches(".*[^ ].*", "We can't name it something empty!"),
});

function NewWorkspaceScreen() {
  const formikRef = useRef();

  const [loading, setLoading] = useState(false);

  const [inputStage, setInputStage] = useState("name");

  const [workspaceNameText, setWorkspaceNameText] = useState("");
  const [workspaceDescriptionText, setWorkspaceDescriptionText] = useState("");
  const [workspaceUsernameText, setWorkspaceUsernameText] = useState("");

  const [usernameError, setUsernameError] = useState();
  const [nameError, setNameError] = useState();

  const navigate = useNavigate();
  const debouncedSearch = useDebounce(workspaceUsernameText, 500);

  useEffect(() => {
    if (debouncedSearch) {
      checkWorkspaceExists(workspaceUsernameText).then((res) => {
        if (res.exists) {
          setUsernameError("Sorry that already exists.");
        } else {
          setUsernameError(false);
        }
      });
    }
  }, [debouncedSearch]);

  const handleCreateWorkspace = () => {
    if (workspaceUsernameText.length < 3) {
      setUsernameError("Must be at least 3 characters.");
      return;
    }

    if (workspaceUsernameText.length > 30) {
      setUsernameError("Cant' be more than 30 characters.");
      return;
    }

    if (!/^[a-z0-9_]+$/gi.test(workspaceUsernameText)) {
      setUsernameError("Please only numbers, letters, and underscores.");
      return;
    }

    setLoading(true);

    checkWorkspaceExists(workspaceUsernameText).then((res) => {
      if (res.exists) {
        setUsernameError("Sorry that already exists.");
        setLoading(false);
      } else {
        createWorkspace({
          workspaceName: workspaceNameText,
          id: workspaceUsernameText,
          workspaceDescription: workspaceDescriptionText,
        }).then((res) => navigate("/w/" + res.id));
      }
    });
  };

  return (
    <>
      <div
        style={{
          height: "84vh",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <div
          style={{
            border: "1px solid #CDCDCD",
            backgroundColor: "#FFF",
            boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.05)",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            padding: "3em 3em",
            borderRadius: "8px",
            width: "20em",
          }}
        >
          <CustomText
            style={{
              color: "#747474",
              fontSize: "20px",
              fontWeight: "700",
              marginBottom: "1em",
            }}
          >
            Create a new workspace
          </CustomText>
          {/* <div
            style={{
              backgroundColor: "yellow",
              marginTop: "2em",
              height: "10em",
              width: "10em",
              borderRadius: "1.5em",
            }}
          ></div>
          <a onClick={() => alert("Needs to be implented :)")}>
            <CustomText
              style={{
                color: "#747474",
                fontSize: "12px",
                fontWeight: "400",
                marginTop: "1.5em",
              }}
            >
              Upload a photo
            </CustomText>
          </a> */}
          {/* <div style={{ width: "100%" }}>
            <div style={{ marginTop: "2em" }}>
              <CustomTextField
                onChange={(e) => setWorkspaceName(e.target.value)}
                value={workspaceName}
                placeholder="Workspace name..."
                required={true}
              />
            </div>
            <CustomButton
              style={{
                borderRadius: "6px",
                height: "2em",
                backgroundColor: "#414141",
                marginTop: "2em",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
              disabled={loading}
              onClick={() => handleCreateWorkspace()}
            >
              {loading ? (
                <LoadSpinner />
              ) : (
                <div>
                  <CustomText
                    style={{
                      fontSize: "14px",
                      fontWeight: "400",
                      color: "#FFF",
                      textAlign: "center",
                    }}
                  >
                    Let's rock and roll!
                  </CustomText>
                </div>
              )}
            </CustomButton>
          </div> */}

          <>
            <div style={{ width: "100%" }}>
              {inputStage === "name" && (
                <>
                  <CustomTextField
                    name="name"
                    placeholder="Workspace name..."
                    value={workspaceNameText}
                    onChange={(e) => setWorkspaceNameText(e.target.value)}
                    required
                    maxLength={30}
                  />
                  <div style={{ margin: "0.5em 0em", height: "0.5em" }}>
                    {nameError && (
                      <CustomText
                        style={{
                          fontSize: "10px",
                          fontWeight: "400",
                          color: "red",
                        }}
                      >
                        {nameError}
                      </CustomText>
                    )}
                  </div>
                  <CustomButton
                    style={{
                      borderRadius: "6px",
                      height: "2em",
                      backgroundColor: "#414141",
                      marginTop: "1em",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                    onClick={() => {
                      if (workspaceNameText.length === 0) {
                        setNameError("Can't be empty!");
                        return;
                      }
                      setInputStage("description");
                    }}
                    disabled={loading}
                  >
                    <CustomText
                      style={{
                        fontSize: "14px",
                        fontWeight: "400",
                        color: "#FFF",
                        textAlign: "center",
                      }}
                    >
                      Next
                    </CustomText>
                  </CustomButton>
                  <a
                    onClick={() => navigate("/discover")}
                    style={{ cursor: "pointer", height: "1em" }}
                  >
                    <CustomText
                      style={{
                        fontSize: "12px",
                        fontWeight: "400",
                        color: "#555555",
                        marginTop: "2em",
                        textAlign: "center",
                      }}
                    >
                      Need to see some samples?
                    </CustomText>
                  </a>
                </>
              )}
              {inputStage === "description" && (
                <>
                  <CustomTextField
                    name="description"
                    placeholder="Workspace description..."
                    value={workspaceDescriptionText}
                    onChange={(e) =>
                      setWorkspaceDescriptionText(e.target.value)
                    }
                    required
                    maxLength={100}
                  />
                  <div style={{ margin: "0.5em 0em", height: "0.5em" }}>
                    {/* {usernameError && (
                      <CustomText
                        style={{
                          fontSize: "10px",
                          fontWeight: "400",
                          color: "red",
                        }}
                      >
                        Sorry that already exists.
                      </CustomText>
                    )} */}
                  </div>
                  <CustomButton
                    style={{
                      borderRadius: "6px",
                      height: "2em",
                      backgroundColor: "#414141",
                      marginTop: "1em",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                    onClick={() => setInputStage("username")}
                    disabled={loading}
                  >
                    <CustomText
                      style={{
                        fontSize: "14px",
                        fontWeight: "400",
                        color: "#FFF",
                        textAlign: "center",
                      }}
                    >
                      Next
                    </CustomText>
                  </CustomButton>
                  <a
                    onClick={() => setInputStage("name")}
                    style={{ cursor: "pointer", height: "1em" }}
                  >
                    <CustomText
                      style={{
                        fontSize: "12px",
                        fontWeight: "400",
                        color: "#555555",
                        marginTop: "2em",
                        textAlign: "center",
                      }}
                    >
                      Go back and re-enter name?
                    </CustomText>
                  </a>
                </>
              )}
              {inputStage === "username" && (
                <>
                  <CustomTextField
                    name="username"
                    placeholder="Workspace URL..."
                    value={workspaceUsernameText}
                    onChange={(e) => setWorkspaceUsernameText(e.target.value)}
                    maxLength={30}
                    required
                  />
                  <div style={{ margin: "0.5em 0em", height: "0.5em" }}>
                    {usernameError ? (
                      <CustomText
                        style={{
                          fontSize: "10px",
                          fontWeight: "400",
                          color: "red",
                        }}
                      >
                        {usernameError}
                      </CustomText>
                    ) : (
                      <CustomText
                        style={{
                          fontSize: "10px",
                          fontWeight: "700",
                          color: "#222222",
                        }}
                      >
                        blabberblabber.com/w/{workspaceUsernameText}
                      </CustomText>
                    )}
                  </div>

                  <CustomButton
                    style={{
                      borderRadius: "6px",
                      height: "2em",
                      backgroundColor: "#414141",
                      marginTop: "1em",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                    onClick={handleCreateWorkspace}
                    disabled={loading}
                  >
                    {loading ? (
                      <LoadSpinner />
                    ) : (
                      <CustomText
                        style={{
                          fontSize: "14px",
                          fontWeight: "400",
                          color: "#FFF",
                          textAlign: "center",
                        }}
                      >
                        Let's rock and roll!
                      </CustomText>
                    )}
                  </CustomButton>
                  <a
                    onClick={() => setInputStage("description")}
                    style={{ cursor: "pointer", height: "1em" }}
                  >
                    <CustomText
                      style={{
                        fontSize: "12px",
                        fontWeight: "400",
                        color: "#555555",
                        marginTop: "2em",
                        textAlign: "center",
                      }}
                    >
                      Re-enter description?
                    </CustomText>
                  </a>
                </>
              )}
            </div>
          </>
        </div>
      </div>
    </>
  );
}

export default NewWorkspaceScreen;
