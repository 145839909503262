import axios from "../lib/axios";
import { auth } from "../firebase/init";

const callApi = (type, url, payload = null) => {
  const jwt = localStorage.getItem("jwt");
  axios.defaults.headers.common["Authorization"] = `Bearer ${jwt}`;
  let request;

  switch (type) {
    case "get":
      request = axios.get(url);
      break;
    case "post":
      request = axios.post(url, payload);
      break;
    case "patch":
      request = axios.patch(url, payload);
      break;
    default:
    // return
  }

  const req = request.then(async (res) => {
    return res.data?.data ? res.data.data : res.data;
  });

  return req.catch((err) => {
    // console.log(err);
    if (err.response?.status === 403) {
      // console.log("Unauthorized");
      return auth.currentUser.getIdToken(true).then((idToken) => {
        localStorage.setItem("jwt", idToken);
        // console.log(idToken);
        return req
          .then((res) => {
            // console.log(res);
          })
          .catch((err) => {
            // console.log(err);
            auth.signOut();
          });
      });
    }
    throw err;
  });
};

export const axiosGet = (url) => {
  return callApi("get", url, null);
};

export const axiosPost = (url, payload) => {
  return callApi("post", url, payload);
};

export const axiosPatch = (url, payload) => {
  return callApi("patch", url, payload);
};
