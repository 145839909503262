import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import useDebounce from "../lib/useDebounce";

import { Formik } from "formik";
import { useState, useEffect } from "react";
import avatar from "animal-avatar-generator";
import * as Yup from "yup";

import CustomText from "../components/CustomText";
import CustomTextField from "../components/CustomTextField";
import CustomButton from "../components/CustomButton";
import { generateNameString } from "../lib/NameGenerator/NameGenerator";

import "../components/TopBar/TopBar.css";
import { checkUserExists, updateUser } from "../api/user";
import LoadSpinner from "../components/LoadSpinner";
import { updateUserAction } from "../redux/actions/userActions";

const nameSchema = Yup.object().shape({
  name: Yup.string()
    // .min(, "Must be at least 8 characters")
    .required("A username is required")
    .min(4, "Needs 4 characters minimum")
    .matches(
      "^[a-zA-Z0-9_]*$",
      "Please only letters, numbers, and underscores"
    ),
});

const generatePfp = () => {
  return avatar((Math.random() + 1).toString(36), {
    size: 150,
    avatarColors: [
      "#d7b89c",
      "#b18272",
      "#ec8a90",
      "#a1Ac88",
      "#99c9bd",
      "#50c8c6", //all above default
      "#EC5F2F",
      "#7AA120",
      "#9A66EF",
      "#509DC8",
      "#E03A3A",
      "#C8509F",
      "#C85050",
      "#A16E20",
      "#FB9639",
      "#39D8FB",
      "#D15BC5",
      "#98F26D",
      "#1FFF78",
      "#FF5B37",
      "#61AAFF",
      "#877CFF",
      "#FF5592",
      "#BA4AFF",
      "#C86650",
      "#C87B50",
      "#C8A650",
      "#C8C350",
      "#A2C850",
      "#85C850",
      "#68C850",
      "#50C872",
      "#50C8AB",
      "#50B2C8",
      "#5096C8",
      "#5063C8",
      "#6150C8",
      "#8C50C8",
      "#C650C8",
      "#AC9188",
      "#ABAC88",
      "#8FAC88",
      "#88AC9B",
      "#8896AC",
      "#8889AC",
      "#A088AC",
      "#AC8897",
    ],
    backgroundColors: [
      "#fcf7d1",
      "#ece2e1",
      "#e4e3cd",
      "#c4ddd6",
      "#b5f4bc", //all above default
      "#E7F4B5",
      "#B5D6F4",
      "#DCB5F4",
      "#F4B5B5",
      "#F4C0B5",
      "#F4B5CC",
      "#C2F4B5",
      "#D8F4B5",
      "#B5F0F4",
      "#B6B5F4",
      "#F4B5EE",
      "#B5F4D6",
      "#DDC4C4",
      "#DDD0C4",
      "#CCDDC4",
      "#C4C4DD",
      "#C4DDD1",
      "#C4D9DD",
      "#D1C4DD",
      "#DDC4D9",
      "#FCD1D1",
      "#FCE3D1",
      "#F1FCD1",
      "#E9FCD1",
      "#E1FCD1",
      "#D1FCDD",
      "#D1FCF9",
      "#D1F4FC",
      "#D1E0FC",
      "#E9D1FC",
      "#FCD1F3",
      "#FCD1E3",
    ],
  });
};

function OnboardingScreen() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const onboarded = useSelector((state) => state.auth.onboarded);
  const authUser = useSelector((state) => state.auth);

  const [name, setName] = useState(generateNameString());
  const [avatarSVG, setAvatarSVG] = useState(generatePfp());

  const [nameError, setNameError] = useState("");

  const debouncedSearch = useDebounce(name, 500);

  useEffect(() => {
    if (debouncedSearch) {
      checkUserExists(name).then((res) => {
        if (res.exists) {
          setNameError("Sorry that username is taken.");
        } else {
          setNameError(false);
        }
      });
    }
  }, [debouncedSearch]);

  useEffect(() => {
    // console.log(onboarded);
    if (onboarded) {
      navigate("/u/" + authUser.username);
    }
    // else if (onboarded !== false) {
    //   navigate("/signup");
    // }
  }, [onboarded]);

  // useEffect(() => {
  //   console.log(onboarded);
  // }, [onboarded]);

  const handleSubmit = () => {
    if (name.length < 4) {
      setNameError("Must be at least 4 characters.");
      return;
    }

    if (!/^[a-z0-9_]+$/gi.test(name)) {
      setNameError("Please only letters, numbers, and underscores.");
      return;
    }

    setLoading(true);

    checkUserExists(name).then((res) => {
      if (res.exists) {
        setNameError("Sorry that username is taken.");
        setLoading(false);
      } else {
        const uid = localStorage.getItem("uid");
        updateUser(uid, {
          username: name,
          pfp: avatarSVG,
        }).then(() => {
          dispatch(
            updateUserAction({
              onboarded: true,
              username: name,
              pfp: avatarSVG,
            })
          );
          navigate("/u/" + name);
        });
      }
    });

    console.log(name);
  };

  return (
    <>
      {/* Top Nav Bar */}
      <>
        <div className="NavBar">
          <div className="NavBarSection">
            <CustomText
              style={{ fontWeight: "700", fontSize: "20px", color: "#555555" }}
            >
              Blabberblabber
            </CustomText>
          </div>
        </div>
      </>
      {/* Body */}

      <div
        style={{
          height: "84vh",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {onboarded === false && (
          <div
            style={{
              border: "1px solid #CDCDCD",
              backgroundColor: "#FFF",
              boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.05)",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              padding: "3em",
              borderRadius: "8px",
              width: "20em",
            }}
          >
            <CustomText
              style={{
                fontSize: "16px",
                fontWeight: "700",
                color: "#555555",
                textAlign: "center",
              }}
            >
              Welcome to Blabberblabber!!
            </CustomText>
            <CustomText
              style={{
                fontSize: "12px",
                fontWeight: "400",
                color: "#555555",
                textAlign: "center",
                padding: "2em",
              }}
            >
              We generated a username for you, but feel free to rename yourself
              before we move on.
            </CustomText>
            {/* <div
            style={{
              backgroundColor: "red",
              width: "6em",
              height: "6em",
              marginTop: "1em",
            }}
          ></div> */}
            <img
              src={`data:image/svg+xml;utf8,${encodeURIComponent(avatarSVG)}`}
              style={{ margin: "1em 0em 2em 0em" }}
              alt="avatarSVG"
            />
            <>
              {/* <Formik
                initialValues={{ name: name.first + name.last }}
                validationSchema={nameSchema}
                onSubmit={(values) => {
                  setLoading(true);
                  const uid = localStorage.getItem("uid");
                  updateUser(uid, {
                    username: values.name,
                    pfp: avatarSVG,
                  }).then(() => {
                    dispatch(
                      updateUserAction({
                        onboarded: true,
                        username: values.name,
                        pfp: avatarSVG,
                      })
                    );
                    navigate("/u/" + uid);
                  });
                }}
              >
                {({ handleChange, handleSubmit, values, errors, touched }) => (
                  <>
                    <div style={{ width: "100%" }}>
                      <CustomTextField
                        formik
                        name="name"
                        placeholder="Name"
                        onChange={handleChange}
                        values={values}
                        errors={errors}
                        touched={touched}
                        required
                        maxLength={26}
                      />
                      <a
                        onClick={() => {
                          setAvatarSVG(generatePfp());
                        }}
                        style={{ cursor: "pointer" }}
                      >
                        <CustomText
                          style={{
                            fontSize: "12px",
                            fontWeight: "400",
                            color: "#555555",
                            textAlign: "center",
                            marginTop: "1em",
                          }}
                        >
                          Re-generate picture?
                        </CustomText>
                      </a>
                      <CustomButton
                        style={{
                          borderRadius: "6px",
                          height: "2em",
                          backgroundColor: "#414141",
                          marginTop: "1em",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                        onClick={handleSubmit}
                        disabled={loading}
                      >
                        {loading ? (
                          <LoadSpinner />
                        ) : (
                          <CustomText
                            style={{
                              fontSize: "14px",
                              fontWeight: "400",
                              color: "#FFF",
                              textAlign: "center",
                            }}
                          >
                            Looks good, let me in!
                          </CustomText>
                        )}
                      </CustomButton>
                    </div>
                  </>
                )}
              </Formik> */}
              <div style={{ width: "100%" }}>
                <CustomTextField
                  name="name"
                  placeholder="Name"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  maxLength={26}
                  required
                />
                <div style={{ margin: "0.5em 0em", height: "0.5em" }}>
                  {setNameError && (
                    <CustomText
                      style={{
                        fontSize: "10px",
                        fontWeight: "400",
                        color: "red",
                      }}
                    >
                      {nameError}
                    </CustomText>
                  )}
                </div>
                <CustomButton
                  style={{
                    borderRadius: "6px",
                    height: "2em",
                    backgroundColor: "#414141",
                    marginTop: "1em",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                  onClick={handleSubmit}
                  disabled={loading}
                >
                  {loading ? (
                    <LoadSpinner />
                  ) : (
                    <CustomText
                      style={{
                        fontSize: "14px",
                        fontWeight: "400",
                        color: "#FFF",
                        textAlign: "center",
                      }}
                    >
                      Looks good, let me in!
                    </CustomText>
                  )}
                </CustomButton>
              </div>
            </>
          </div>
        )}
      </div>

      {/* Bottom Bar */}
      <>
        <div className="NavBar" style={{ borderTop: "1px solid #cdcdcd" }}>
          <div className="NavBarSection">
            <CustomText
              style={{ fontWeight: "400", fontSize: "16px", color: "#949494" }}
            >
              Blabberblabber
            </CustomText>
          </div>
          <div className="NavBarSection" style={{ justifyContent: "flex-end" }}>
            <a
              href={"https://airtable.com/shrnYoM4ErhoVkcck"}
              style={{ textDecoration: "none" }}
              target="_blank"
              rel="noopener noreferrer"
            >
              <CustomText
                style={{
                  fontWeight: "400",
                  fontSize: "16px",
                  color: "#949494",
                  marginRight: "2em",
                }}
              >
                Support
              </CustomText>
            </a>
            <div onClick={() => navigate("/terms")}>
              <CustomText
                style={{
                  fontWeight: "400",
                  fontSize: "16px",
                  color: "#949494",
                  marginRight: "2em",
                  cursor: "pointer",
                }}
              >
                Terms of Service
              </CustomText>
            </div>
            <div onClick={() => navigate("/privacy")}>
              <CustomText
                style={{
                  fontWeight: "400",
                  fontSize: "16px",
                  color: "#949494",
                  cursor: "pointer",
                }}
              >
                Privacy Policy
              </CustomText>
            </div>
          </div>
        </div>
      </>
    </>
  );
}

export default OnboardingScreen;
