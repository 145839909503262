import { useState, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { $getRoot } from "lexical";

import PFPSVG from "../../assets/PFP.svg";
import CustomText from "../CustomText";
import CustomButton from "../CustomButton";
import CommentStatic from "../../lexical/CommentStatic";
import NewThreadEditor from "../../lexical/NewThreadEditor";
import LoadSpinner from "../LoadSpinner";
import PopupSignup from "../PopupSignup";
import {
  timeSince,
  countUpvotesMinusDownvotes,
} from "../../lib/transformationUtil";
import CommentBlockSingleSub from "./CommentBlockSingleSub";

import { createThreadReply } from "../../api/thread";

import "./CommentBlock.css";
import { handleVote } from "../../lib/voteUtil";
import { useDispatch } from "react-redux";
import { addReplyToStoreAction } from "../../redux/actions/threadActions";

const styles = {
  reply: {
    commentBlock: {
      display: "flex",
      flexDirection: "row",
      padding: "0em 1em",
    },
    replyText: {
      color: "#555555",
      fontSize: 16,
    },
    replyButtonText: {
      color: "#555555",
      fontSize: 12,
      fontWeight: "bold",
      marginRight: "1em",
      cursor: "pointer",
    },
    replyLeftColumn: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },

    verticalLine: {
      marginTop: "0.5em",
      width: "0px",
      height: "100%",
      border: "0.5px solid #E0E0E0",
    },
    contentBox: {
      marginLeft: "1em",
      flex: 1,
    },
    replyUserText: {
      margin: "0",
      fontSize: 12,
      color: "#7E7E7E",
    },
    upvoteReplyBar: {
      display: "flex",
      flexDirection: "row",
    },
  },
  subreply: {
    subreplyCommentBlock: {
      display: "flex",
      flexDirection: "row",
      paddingTop: "1em",
    },
  },
};

export default function CommentBlockSingle({ replies }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [popupOpen, setPopupOpen] = useState(false);

  const { threadId, workspaceId } = useParams();
  const currentThreadData = useSelector((state) => state.thread[replies.id]);

  const currentWorkspaceName = useSelector(
    (state) => state.workspace[workspaceId]?.workspaceName
  );

  const [showReply, setShowReply] = useState(false);
  const [loading, setLoading] = useState(false);

  const editorRef = useRef();

  return (
    <>
      <PopupSignup
        popupOpen={popupOpen}
        setPopupOpen={setPopupOpen}
        navigate={navigate}
      />
      <div className="OuterCommentBlock">
        <div style={styles.reply.commentBlock}>
          {/* // PFP and Vline for Reply */}
          <div style={styles.reply.replyLeftColumn}>
            <div onClick={() => navigate("/u/" + replies.poster.username)}>
              <img
                src={`data:image/svg+xml;utf8,${encodeURIComponent(
                  currentThreadData.poster.pfp
                )}`}
                style={{
                  height: "1.5em",
                  width: "1.5em",
                }}
                alt="PFPSVG"
              />
            </div>
            <div style={styles.reply.verticalLine} />
          </div>
          {/* // Reply Content + Nested Subreplies */}
          <div style={styles.reply.contentBox}>
            {/* // 11pt Nancy 22d ago */}
            <CustomText style={styles.reply.replyUserText}>
              <span
                style={{
                  color:
                    localStorage.getItem("uid") in replies.upvotes
                      ? "#5891E5"
                      : "#7E7E7E",
                }}
              >
                {countUpvotesMinusDownvotes(replies.upvotes)}
                pts
              </span>{" "}
              •{" "}
              <span
                onClick={() => navigate("/u/" + replies.poster.username)}
                style={{ cursor: "pointer" }}
              >
                {currentThreadData.poster.usernameStyled}
              </span>{" "}
              • {timeSince(currentThreadData.createdAt)} ago
            </CustomText>
            {/* // Reply text here */}
            <div style={styles.reply.replyText}>
              <CommentStatic initialEditorState={currentThreadData.content} />
            </div>
            {/* // Upvote Reply */}
            <div style={styles.reply.upvoteReplyBar}>
              <a
                onClick={() =>
                  handleVote(
                    replies.id,
                    localStorage.getItem("uid"),
                    "upvote",
                    dispatch,
                    setPopupOpen,
                    null
                  )
                }
              >
                <CustomText
                  style={{
                    ...styles.reply.replyButtonText,
                    color: replies.upvotes[localStorage.getItem("uid")]
                      ? "#5891E5"
                      : "#555555",
                  }}
                >
                  Upvote
                </CustomText>
              </a>
              <a
                onClick={() =>
                  handleVote(
                    replies.id,
                    localStorage.getItem("uid"),
                    "downvote",
                    dispatch,
                    setPopupOpen,
                    null
                  )
                }
              >
                <CustomText
                  style={{
                    ...styles.reply.replyButtonText,
                    color:
                      replies.upvotes[localStorage.getItem("uid")] === false
                        ? "#5891E5"
                        : "#555555",
                  }}
                >
                  Downvote
                </CustomText>
              </a>
              <a
                onClick={() => {
                  if (localStorage.getItem("uid")) {
                    setShowReply(true);
                  } else {
                    setPopupOpen(true);
                  }
                }}
              >
                <CustomText style={styles.reply.replyButtonText}>
                  Reply
                </CustomText>
              </a>
            </div>
            {/* // Subreply */}
            {Object.values(currentThreadData.threads)
              .sort((a, b) => a.createdAt - b.createdAt)
              .map((subreplies) => (
                <CommentBlockSingleSub
                  subreplies={subreplies}
                  setShowReply={setShowReply}
                  key={subreplies.id}
                />
              ))}
            {showReply && (
              <div
                style={{
                  padding: "1em 0em 0em 0em",
                }}
              >
                <NewThreadEditor editorRef={editorRef} />
                <div
                  style={{
                    marginTop: "1em",
                    display: "flex",
                    flexDirection: "row",
                  }}
                >
                  <CustomButton
                    disabled={loading}
                    style={{
                      backgroundColor: "#E4E4E4",
                      width: "6em",
                      height: "0.8em",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                    onClick={() => {
                      setLoading(true);
                      const editorStateTextString = editorRef.current
                        .getEditorState()
                        .read(() => {
                          return $getRoot().getTextContent();
                        });
                      createThreadReply(replies.id, {
                        content: JSON.stringify(
                          editorRef.current.getEditorState()
                        ),
                        contentPlain: editorStateTextString,
                        parent: replies.id,
                        topParent: threadId,
                        workspace: {
                          workspaceId: replies.workspace.workspaceId,
                          workspaceName: currentWorkspaceName,
                        },
                      }).then((res) => {
                        // console.log(res);
                        dispatch(addReplyToStoreAction(res));
                        setLoading(false);
                        setShowReply(false);
                      });
                    }}
                  >
                    {loading ? (
                      <LoadSpinner height={"1em"} />
                    ) : (
                      <CustomText style={{ color: "#747474" }}>
                        Post Comment
                      </CustomText>
                    )}
                  </CustomButton>
                  <CustomButton
                    disabled={false}
                    style={{
                      backgroundColor: "#FFFFFF",
                      width: "1em",
                      height: "0.8em",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      marginLeft: "1em",
                    }}
                    onClick={() => setShowReply(false)}
                  >
                    <CustomText style={{ color: "#747474" }}>Cancel</CustomText>
                  </CustomButton>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
