import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import CustomText from "../CustomText";

import FireSVG from "../../assets/Fire.svg";
import HourglassSVG from "../../assets/Hourglass.svg";
import TrophySVG from "../../assets/Trophy.svg";
import AddPostSVG from "../../assets/AddPost.svg";

import "./Body.css";
import PopupSignup from "../PopupSignup";

export default function BodySortBlock({ contentCategory, setContentCategory }) {
  const navigate = useNavigate();
  const [popupOpen, setPopupOpen] = useState(false);
  const { workspaceId } = useParams();

  return (
    <>
      <PopupSignup
        popupOpen={popupOpen}
        setPopupOpen={setPopupOpen}
        navigate={navigate}
      />
      <div className="BodySortBlock">
        <div style={{ display: "flex" }}>
          <div
            className={
              contentCategory === "hot"
                ? "BodySortButton BodySortButtonSelected"
                : "BodySortButton"
            }
            onClick={() => setContentCategory("hot")}
          >
            <img
              src={FireSVG}
              style={{ height: "1em", width: "1em", marginRight: "0.5em" }}
              alt="FireSVG"
            />
            <CustomText style={{ color: "#7E7E7E", fontWeight: "700" }}>
              Hot
            </CustomText>
          </div>
          <div
            className={
              contentCategory === "new"
                ? "BodySortButton BodySortButtonSelected"
                : "BodySortButton"
            }
            onClick={() => setContentCategory("new")}
          >
            <img
              src={HourglassSVG}
              style={{ height: "1em", width: "1em", marginRight: "0.5em" }}
              alt="HourglassSVG"
            />
            <CustomText style={{ color: "#7E7E7E", fontWeight: "700" }}>
              New
            </CustomText>
          </div>
          <div
            className={
              contentCategory === "top"
                ? "BodySortButton BodySortButtonSelected"
                : "BodySortButton"
            }
            onClick={() => setContentCategory("top")}
          >
            <img
              src={TrophySVG}
              style={{ height: "1em", width: "1em", marginRight: "0.5em" }}
              alt="TrophySVG"
            />
            <CustomText style={{ color: "#7E7E7E", fontWeight: "700" }}>
              Top
            </CustomText>
          </div>
        </div>
        <div
          className="AddPostButton"
          onClick={() => {
            if (localStorage.getItem("uid")) {
              console.log(localStorage.getItem("uid"));
              navigate("/w/" + workspaceId + "/create");
            } else {
              setPopupOpen(true);
            }
          }}
        >
          <img
            src={AddPostSVG}
            style={{ height: "1em", width: "1em", marginRight: "0.5em" }}
            alt="AddPostSVG"
          />
          <CustomText style={{ color: "#7E7E7E", fontWeight: "700" }}>
            New Post
          </CustomText>
        </div>
      </div>
    </>
  );
}
