import userReducer from "./userReducer";
import threadReducer from "./threadReducer";
import workspaceReducer from "./workspaceReducer";
import userThreadsReducer from "./userThreadsReducer";
import userWorkspacesReducer from "./userWorkspacesReducer";
import authReducer from "./authReducer";
import notificationReducer from "./notifcationReducer";

const { combineReducers } = require("redux");

const rootReducer = combineReducers({
  user: userReducer,
  auth: authReducer,
  userThreads: userThreadsReducer,
  userWorkspaces: userWorkspacesReducer,
  workspace: workspaceReducer,
  thread: threadReducer,
  notification: notificationReducer,
});

export default rootReducer;
