import ExampleTheme from "./themes/ExampleTheme";
import { LexicalComposer } from "@lexical/react/LexicalComposer";
import { RichTextPlugin } from "@lexical/react/LexicalRichTextPlugin";

import { ContentEditable } from "@lexical/react/LexicalContentEditable";
import { HistoryPlugin } from "@lexical/react/LexicalHistoryPlugin";
import { AutoFocusPlugin } from "@lexical/react/LexicalAutoFocusPlugin";
import ToolbarPlugin from "./plugins/ToolbarPlugin";
import { HeadingNode, QuoteNode } from "@lexical/rich-text";
import { TableCellNode, TableNode, TableRowNode } from "@lexical/table";
import { ListItemNode, ListNode } from "@lexical/list";
import { CodeHighlightNode, CodeNode } from "@lexical/code";
import { AutoLinkNode, LinkNode } from "@lexical/link";
import { LinkPlugin } from "@lexical/react/LexicalLinkPlugin";
import { ListPlugin } from "@lexical/react/LexicalListPlugin";
import { MarkdownShortcutPlugin } from "@lexical/react/LexicalMarkdownShortcutPlugin";
import { TRANSFORMERS } from "@lexical/markdown";

import ListMaxIndentLevelPlugin from "./plugins/ListMaxIndentLevelPlugin";
import CodeHighlightPlugin from "./plugins/CodeHighlightPlugin";
import AutoLinkPlugin from "./plugins/AutoLinkPlugin";


import {OnChangePlugin} from '@lexical/react/LexicalOnChangePlugin';
import { useRef, useEffect } from "react";


// function Placeholder() {
//   return <div className="editor-placeholder">Your reply...</div>;
// }

export default function CommentEditor({ initialEditorState }) {

  const editorConfig = {
    // The editor theme
    theme: ExampleTheme,
    // Handling of errors during update
    onError(error) {
      throw error;
    },
    // Any custom nodes go here
    nodes: [
      HeadingNode,
      ListNode,
      ListItemNode,
      QuoteNode,
      CodeNode,
      CodeHighlightNode,
      TableNode,
      TableCellNode,
      TableRowNode,
      AutoLinkNode,
      LinkNode
    ],
    editorState: initialEditorState, 
    editable: false,
  };

  return (
    <>
    <LexicalComposer initialConfig={editorConfig}>
      <div >
        {/* <ToolbarPlugin/> */}
        <div >
          <RichTextPlugin
            contentEditable={<ContentEditable style={{fontFamily: "Open Sans", marginTop: "0.5em", marginBottom: "0.5em"}}/>}
            // placeholder={<Placeholder />}
          />
          {/* <HistoryPlugin /> */}
          {/* <TreeViewPlugin /> */}
          {/* <AutoFocusPlugin /> */}
          <CodeHighlightPlugin />
          <ListPlugin />
          <LinkPlugin />
          {/* <AutoLinkPlugin /> */}
          <ListMaxIndentLevelPlugin maxDepth={7} />
          {/* <MarkdownShortcutPlugin transformers={TRANSFORMERS} /> */}
          {/* <OnChangePlugin onChange={_onChange}/> */}
          {/* <ValuePlugin value={replies[replyId]}/> */}
        </div>
      </div>
    </LexicalComposer>
    </>
  );
}
