import {
  LOGIN,
  LOGOUT,
  SIGNUP,
  UPDATE_USER,
  ADD_USER,
  WORKSPACES_OF_USER,
  THREADS_OF_USER,
  REDUCE_UNREAD,
} from "../actions/types";
import {
  extractObjectFromObject,
  extractObjectFromArray,
} from "../../lib/transformationUtil";
import { connectFirestoreEmulator } from "firebase/firestore";

const INITIAL_STATE = {};

const authReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case LOGIN:
      return {
        ...action.payload.data,
      };
    case UPDATE_USER:
      return {
        ...state,
        ...action.payload,
      };
    case REDUCE_UNREAD:
      return { ...state, ["unread"]: state["unread"] - 1 };
    case LOGOUT:
      return INITIAL_STATE;

    default:
      return state;
  }
};

export default authReducer;
