import { axiosGet, axiosPost, axiosPatch } from "../lib/apiUtil";

export const getThreadsByUserId = (userId) => {
  return axiosGet(`/user/${userId}/threads`);
};

export const createThread = (threadData) => {
  return axiosPost(`/thread`, threadData);
};

export const createThreadReply = (threadParentId, threadData) => {
  return axiosPost(`/thread/${threadParentId}`, threadData);
};

export const getThreadById = (threadId) => {
  return axiosGet(`/thread/${threadId}`);
};

export const voteThreadById = (threadId, userId, voteType) => {
  return axiosPatch(`/thread/${threadId}/${voteType}`, { userId: userId });
};

// app.patch("/thread/:threadId/upvote", FBAuth, upvoteThread);
// app.patch("/thread/:threadId/downvote", FBAuth, downvoteThread);

// app.post("/thread/:threadId", FBAuth, createThread);

// app.get("/user/:userId/threads", FBAuth, getUserThreads);

// app.get("/thread/:threadId", FBAuth, getThread);

// app.post("/thread", FBAuth, createThread);
