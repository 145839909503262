import {
  ADD_NOTIFICATION_TO_STORE,
  MARK_NOTIFICATION_AS_READ,
} from "../actions/types";
// import {
//   extractObjectFromObject,
//   extractObjectFromArray,
// } from "../../lib/transformationUtil";
// import { connectFirestoreEmulator } from "firebase/firestore";

const INITIAL_STATE = {};

const notificationReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ADD_NOTIFICATION_TO_STORE:
      return { data: action.payload };
    case MARK_NOTIFICATION_AS_READ:
      const stateCopy = state["data"];
      // console.log(stateCopy);
      // console.log(action.payload);

      return {
        ...state,
        data: state.data.map((notification) =>
          notification.id === action.payload
            ? { ...notification, read: true }
            : notification
        ),
      };
    default:
      return state;
  }
};

export default notificationReducer;
