import Backdrop from "@mui/material/Backdrop";

import CustomButton from "./CustomButton";
import CustomText from "./CustomText";
import PartyFaceSVG from "../assets/PartyFace.svg";

export default function PopupSignup({ popupOpen, setPopupOpen, navigate }) {
  return (
    <div>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={popupOpen}
        onClick={() => setPopupOpen(false)}
      >
        <div
          style={{
            border: "1px solid #CDCDCD",
            backgroundColor: "#FFF",
            boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.05)",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            padding: "4em 3em",
            borderRadius: "8px",
            // width: "20%",
          }}
        >
          <CustomText
            style={{
              fontSize: "16px",
              fontWeight: "700",
              color: "#555555",
              textAlign: "center",
              marginBottom: "1em",
            }}
          >
            The party is more fun with you here...
          </CustomText>
          <CustomText
            style={{
              fontSize: "16px",
              fontWeight: "700",
              color: "#555555",
              textAlign: "center",
              marginBottom: "1em",
            }}
          >
            Join the conversation?
          </CustomText>
          <img
            src={PartyFaceSVG}
            style={{ height: "5em", width: "5em", marginBlock: "1em" }}
            alt="PartyFaceSVG"
          />
          <>
            <CustomButton
              style={{
                borderRadius: "6px",
                height: "2em",
                width: "100%",
                backgroundColor: "#414141",
                marginTop: "1em",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
              onClick={() => navigate("/signup")}
            >
              <CustomText
                style={{
                  fontSize: "14px",
                  fontWeight: "400",
                  color: "#FFF",
                  textAlign: "center",
                }}
              >
                Sign Up
              </CustomText>
            </CustomButton>
            <CustomButton
              style={{
                borderRadius: "6px",
                height: "2em",
                width: "100%",
                backgroundColor: "#FFF",
                border: "1px solid #CDCDCD",
                marginTop: "1em",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
              onClick={() => navigate("/login")}
            >
              <CustomText
                style={{
                  fontSize: "14px",
                  fontWeight: "400",
                  color: "#000",
                  textAlign: "center",
                }}
              >
                Log in
              </CustomText>
            </CustomButton>
          </>
        </div>
      </Backdrop>
    </div>
  );
}
