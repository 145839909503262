import { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import "./Body.css";
import BodySortBlock from "./BodySortBlock";
import BodyThread from "./BodyThread";
import BodyNoThreads from "./BodyNoThreads";
import CustomText from "../CustomText";

import { getWorkspaceById } from "../../api/workspace";
import { saveWorkspaceToStoreAction } from "../../redux/actions/workspaceActions";
import { getThreadById } from "../../api/thread";
import { saveThreadToStoreAction } from "../../redux/actions/threadActions";

export default function Body() {
  const { workspaceId } = useParams();
  // console.log(workspaceId);
  const dispatch = useDispatch();
  const workspaceThreads = useSelector(
    (state) => state.workspace[workspaceId]?.threads
  );
  const workspaceDescription = useSelector(
    (state) => state.workspace[workspaceId]?.workspaceDescription
  );
  const workspaceName = useSelector(
    (state) => state.workspace[workspaceId]?.workspaceName
  );

  const reduxThreads = useSelector((state) => state.thread);
  const [contentCategory, setContentCategory] = useState("hot");

  useEffect(() => {
    if (!!workspaceId) {
      getWorkspaceById(workspaceId).then((res) => {
        dispatch(saveWorkspaceToStoreAction(res)).then(() => {
          // a UX optimization to pre-load threads
          res.threads
            .sort((a, b) => b.createdAt - a.createdAt)
            .forEach((thread) => {
              //if thread doesn't yet exist in store, save it
              if (!!!reduxThreads[thread.id]) {
                getThreadById(thread.id).then((res) => {
                  dispatch(saveThreadToStoreAction(res));
                });
              }
            });
        });
      });
    }
  }, [workspaceId]);

  return (
    <div
      style={{
        display: "flex",
        height: "calc(100vh - 80px)",
        overflowY: "scroll",
      }}
    >
      <div style={{ flex: 9, padding: "0em 5%" }}>
        <BodySortBlock
          contentCategory={contentCategory}
          setContentCategory={setContentCategory}
        />

        {!!workspaceThreads && workspaceThreads.length !== 0 ? (
          <BodyThread
            threadsData={workspaceThreads}
            contentCategory={contentCategory}
          />
        ) : (
          <BodyNoThreads />
        )}
      </div>
      <div
        style={{
          // backgroundColor: "yellow",
          flex: 4,
          padding: "2.5em 4em 2.5em 0em",
        }}
      >
        {!!workspaceDescription && (
          <>
            <CustomText
              style={{
                color: "#555555",
                fontSize: "12px",
                fontWeight: "700",
              }}
            >
              {workspaceName}
            </CustomText>
            <CustomText
              style={{
                color: "#555555",
                fontSize: "12px",
                fontWeight: "400",
                marginTop: "1em",
              }}
            >
              {workspaceDescription}
            </CustomText>
            <div
              style={{
                width: "100%",
                height: "1px",
                backgroundColor: "#cdcdcd",
                margin: "1em 0em",
              }}
            />{" "}
            <CustomText
              style={{
                color: "#555555",
                fontSize: "12px",
                fontWeight: "700",
                marginTop: "1em",
              }}
            >
              Shareable link:
            </CustomText>
            <CustomText
              style={{
                color: "#555555",
                fontSize: "12px",
                fontWeight: "400",
                marginTop: "1em",
                // textDecoration: "underline"
              }}
            >
              blabberblabber.com/w/{workspaceId}
            </CustomText>
          </>
        )}
      </div>
    </div>
  );
}
