import { useNavigate } from "react-router-dom";

import CustomText from "../CustomText";
import Typewriter from "typewriter-effect";

import "./MainScreenComponents.css";

export default function MainScreenText({}) {
  const navigate = useNavigate();

  return (
    <>
      <CustomText
        style={{
          color: "#555555",
          fontWeight: "700",
          fontSize: "40px",
          textAlign: "center",
        }}
      >
        <CustomText
          style={{
            color: "#6CB126",
            fontWeight: "700",
            fontSize: "40px",
            textAlign: "center",
          }}
          inline={true}
        >
          <Typewriter
            options={{
              strings: ["Communities", "Dev forums", "Class discussions"],
              autoStart: true,
              loop: true,
              delay: 40,
              deleteSpeed: 20,
            }}
          />
        </CustomText>
        thrive on Blabberblabber
      </CustomText>

      <p className="MainScreenText" onClick={() => navigate("/discover")}>
        Launch a customized forum + community with one click.{" "}
        <span style={{ fontWeight: "700" }}>Explore &#8594;</span>
      </p>
    </>
  );
}
