import { axiosGet, axiosPost, axiosPatch } from "../lib/apiUtil";

// THIS FUNCTION IS DEPRECATED, REPLACED WITH loginUser()
// export const getUserById = (userId) => {
//   return axiosGet(`/user/${userId}`);
// };

export const loginUser = () => {
  return axiosGet(`/login`);
};

export const getProfileByUsername = (username) => {
  return axiosGet(`/userdev/${username}`);
};

export const signupUser = (userId, email) => {
  return axiosPost(`/user`, {
    id: userId,
    email: email,
  });
};

export const updateUser = (userId, data) => {
  return axiosPatch(`/user/${userId}`, {
    id: userId,
    ...data,
  });
};

export const getProfileById = (userId) => {
  return axiosGet(`/user/${userId}/profile`);
};

export const checkUserExists = (username) => {
  return axiosPost(`/user/exists/username`, { username: username });
};
