import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import TopBar from "../components/TopBar/TopBar";
import CustomText from "../components/CustomText";
import BowlSpoonSVG from "../assets/BowlSpoon.svg";

import { getNotifications, markNotificationAsRead } from "../api/notification";
import {
  addNotificationsToStoreAction,
  markNotificationAsReadAction,
} from "../redux/actions/notificationActions";
import { useNavigate } from "react-router-dom";

import { getThreadById } from "../api/thread";
import { saveThreadToStoreAction } from "../redux/actions/threadActions";
import { reduceUnreadAction } from "../redux/actions/userActions";

function NotificationScreen() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const notificationsData = useSelector((state) => state.notification.data);
  const reduxThreads = useSelector((state) => state.thread);

  useEffect(() => {
    if (!!!localStorage.getItem("uid")) {
      navigate("/");
    }
    getNotifications().then((res) => {
      dispatch(addNotificationsToStoreAction(res)).then(() => {
        // a UX optimization to pre-load threads
        res.forEach((notification) => {
          //if thread doesn't yet exist in store, save it, but for now just get the unread ones
          if (
            !notification.read &&
            !!!reduxThreads[notification.navigation.topParentThreadId]
          ) {
            getThreadById(notification.navigation.topParentThreadId).then(
              (res) => {
                dispatch(saveThreadToStoreAction(res));
              }
            );
          }
        });
      });
    });
  }, []);
  return (
    <>
      {!!localStorage.getItem("uid") && (
        <div
          style={{
            padding: "0em 5%",
            height: "calc(100vh - 80px)",
            overflowY: "scroll",
          }}
        >
          <CustomText
            style={{
              color: "#747474",
              fontSize: "20px",
              fontWeight: "700",
              margin: "2em 0em 1em",
            }}
          >
            Your recent notifications
          </CustomText>
          {!!!notificationsData || notificationsData.length === 0 ? (
            <div className="BodyThreadItem">
              <div style={{ flex: 4, display: "flex" }}>
                <div
                  style={{
                    flex: 1,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    padding: "1em",
                  }}
                >
                  <img
                    src={BowlSpoonSVG}
                    style={{ height: "4em", width: "4em" }}
                    alt="BowlSpoonSVG"
                  />
                </div>
                <div
                  style={{
                    flex: 6,
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-around",
                  }}
                >
                  <CustomText
                    style={{
                      color: "#747474",
                      fontSize: "20px",
                      fontWeight: "700",
                      marginBlock: "0em",
                    }}
                  >
                    No notifications yet...
                  </CustomText>

                  <CustomText
                    style={{
                      color: "#555555",
                      fontSize: "16px",
                      fontWeight: "400",
                      marginBlock: "0em",
                    }}
                  >
                    Once you participate, replies to your posts will come!
                  </CustomText>
                </div>
              </div>
            </div>
          ) : (
            notificationsData
              .sort((a, b) => b.createdAt - a.createdAt)
              .map((notification) => (
                <div
                  className="BodyThreadItem"
                  key={notification.id}
                  style={{
                    border: notification.read
                      ? "1px solid #cdcdcd"
                      : "2px solid #cdcdcd",
                  }}
                  onClick={() => {
                    if (!notification.read) {
                      markNotificationAsRead(notification.id);
                      dispatch(markNotificationAsReadAction(notification.id));
                      dispatch(reduceUnreadAction(notification.id));
                    }
                    navigate(
                      "/w/" +
                        notification.navigation.workspaceId +
                        "/t/" +
                        notification.navigation.topParentThreadId
                    );
                  }}
                >
                  <div style={{ flex: 1, display: "flex" }}>
                    <div
                      style={{
                        flex: "1",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                        padding: "0em 1em",
                      }}
                    >
                      <img
                        src={`data:image/svg+xml;utf8,${encodeURIComponent(
                          notification.from.pfp
                        )}`}
                        style={{ height: "50%" }}
                        alt="PFPSVG"
                      />
                    </div>
                    <div
                      style={{
                        flex: "7",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-around",
                        padding: "0em 1em",
                        // backgroundColor: "red",
                        minWidth: 0,
                      }}
                    >
                      <CustomText
                        style={{
                          color: "#747474",
                          fontSize: "16px",
                          fontWeight: "400",
                          marginBlock: "0em",
                          fontStyle: "italic",
                        }}
                      >
                        {notification.from.username} replied to your comment
                      </CustomText>
                      <CustomText
                        style={{
                          color: "#555555",
                          fontSize: "16px",
                          fontWeight: "400",
                          marginBlock: "0em",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          whiteSpace: "nowrap",
                          maxWidth: "45vw",
                        }}
                      >
                        {notification.message}
                      </CustomText>
                    </div>
                    {!notification.read && (
                      <div
                        style={{
                          flex: "1",
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          justifyContent: "center",
                          padding: "0em 1em",
                        }}
                      >
                        <div
                          style={{
                            backgroundColor: "#cdcdcd",
                            height: "0.5em",
                            width: "0.5em",
                            borderRadius: "0.5em",
                          }}
                        />
                      </div>
                    )}
                  </div>
                </div>
              ))
          )}
        </div>
      )}
    </>
  );
}

export default NotificationScreen;
