import { Formik } from "formik";
import { useState, useCallback, useRef } from "react";
import { useDispatch } from "react-redux";

import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import { createUserWithEmailAndPassword } from "firebase/auth";

import { auth } from "../firebase/init";
import CustomText from "../components/CustomText";
import CustomTextField from "../components/CustomTextField";
import CustomButton from "../components/CustomButton";
import Body from "../components/Body/Body";
import LoadSpinner from "../components/LoadSpinner";

import { signupUser } from "../api/user";

import { loginAction, logoutAction } from "../redux/actions/userActions";

import "../components/TopBar/TopBar.css";

const loginSchemaEmail = Yup.object().shape({
  email: Yup.string()
    .email("Invalid email format")
    .required("Email is required"),
});

const loginSchemaPassword = Yup.object().shape({
  password: Yup.string()
    .min(8, "Must be at least 8 characters")
    .required("Password is required"),
});

function SignupScreen() {
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const formikRef = useRef();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleSignUp = useCallback((email, password) => {
    setLoading(true);
    createUserWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        // Signed in
        dispatch(
          loginAction({
            uid: userCredential.user.uid,
            data: { id: userCredential.user.uid, email, onboarded: false },
          })
        );
        return signupUser(userCredential.user.uid, email);
      })
      .then(() => {
        // console.log("finish signing up");
        // localStorage.setItem("onboarding", true);
        navigate("/onboard");
      })
      .catch((error) => {
        if (error.code == "auth/email-already-in-use") {
          formikRef.current.setErrors({
            password: "That email already exists!",
          });
        } else {
          formikRef.current.setErrors({
            password: "Something went wrong, please try again.",
          });
        }
        setLoading(false);
      });
  }, []);

  return (
    <>
      {/* Top Nav Bar */}
      <>
        <div className="NavBar">
          <div className="NavBarSection">
            <CustomText
              style={{ fontWeight: "700", fontSize: "20px", color: "#555555" }}
            >
              Blabberblabber
            </CustomText>
          </div>
        </div>
      </>
      {/* Body */}
      <div
        style={{
          height: "84vh",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <div
          style={{
            border: "1px solid #CDCDCD",
            backgroundColor: "#FFF",
            boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.05)",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            padding: "3em",
            borderRadius: "8px",
            width: "20%",
          }}
        >
          <CustomText
            style={{
              fontSize: "16px",
              fontWeight: "700",
              color: "#555555",
              textAlign: "center",
              marginBottom: "1em",
            }}
          >
            Sign up to join the converation. <br /> It takes less than 45
            seconds.
          </CustomText>
          {!!!email ? (
            <>
              <Formik
                initialValues={{ email: "", password: "" }}
                validationSchema={loginSchemaEmail}
                onSubmit={(values) => {
                  setEmail(values.email);
                }}
              >
                {({ handleChange, handleSubmit, values, errors, touched }) => (
                  <>
                    <div style={{ width: "100%" }}>
                      <CustomTextField
                        formik
                        name="email"
                        type="email"
                        placeholder="Email"
                        onChange={handleChange}
                        values={values}
                        errors={errors}
                        touched={touched}
                        required
                      />
                      <a>
                        <CustomButton
                          style={{
                            borderRadius: "6px",
                            height: "2em",
                            backgroundColor: "#414141",
                            marginTop: "1em",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                          onClick={handleSubmit}
                        >
                          <CustomText
                            style={{
                              fontSize: "14px",
                              fontWeight: "400",
                              color: "#FFF",
                              textAlign: "center",
                            }}
                          >
                            Next
                          </CustomText>
                        </CustomButton>
                      </a>
                    </div>
                  </>
                )}
              </Formik>
              <a
                onClick={() => navigate("/login")}
                style={{ cursor: "pointer" }}
              >
                <CustomText
                  style={{
                    fontSize: "12px",
                    fontWeight: "400",
                    color: "#555555",
                    marginTop: "2em",
                  }}
                >
                  Already have an account? Login here.
                </CustomText>
              </a>
            </>
          ) : (
            <>
              <Formik
                innerRef={formikRef}
                initialValues={{ email: "", password: "" }}
                validationSchema={loginSchemaPassword}
                onSubmit={(values) => {
                  handleSignUp(email, values.password);
                }}
              >
                {({ handleChange, handleSubmit, values, errors, touched }) => (
                  <>
                    <div style={{ width: "100%" }}>
                      <CustomTextField
                        formik
                        name="password"
                        type="password"
                        placeholder="Create a password"
                        onChange={handleChange}
                        values={values}
                        errors={errors}
                        touched={touched}
                        required
                      />
                      <CustomButton
                        style={{
                          borderRadius: "6px",
                          height: "2em",
                          backgroundColor: "#414141",
                          marginTop: "1em",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                        onClick={handleSubmit}
                        disabled={loading}
                      >
                        {loading ? (
                          <LoadSpinner />
                        ) : (
                          <CustomText
                            style={{
                              fontSize: "14px",
                              fontWeight: "400",
                              color: "#FFF",
                              textAlign: "center",
                            }}
                          >
                            Join
                          </CustomText>
                        )}
                      </CustomButton>
                    </div>
                  </>
                )}
              </Formik>
              <a onClick={() => setEmail("")}>
                <CustomText
                  style={{
                    fontSize: "12px",
                    fontWeight: "400",
                    color: "#555555",
                    marginTop: "2em",
                  }}
                >
                  &lt; Go back.
                </CustomText>
              </a>
            </>
          )}
        </div>
      </div>
      {/* Bottom Bar */}
      <>
        <div className="NavBar" style={{ borderTop: "1px solid #cdcdcd" }}>
          <div className="NavBarSection">
            <CustomText
              style={{ fontWeight: "400", fontSize: "16px", color: "#949494" }}
            >
              Blabberblabber
            </CustomText>
          </div>
          <div className="NavBarSection" style={{ justifyContent: "flex-end" }}>
            <a
              href={"https://airtable.com/shrnYoM4ErhoVkcck"}
              style={{ textDecoration: "none" }}
              target="_blank"
              rel="noopener noreferrer"
            >
              <CustomText
                style={{
                  fontWeight: "400",
                  fontSize: "16px",
                  color: "#949494",
                  marginRight: "2em",
                }}
              >
                Support
              </CustomText>
            </a>
            <div onClick={() => navigate("/terms")}>
              <CustomText
                style={{
                  fontWeight: "400",
                  fontSize: "16px",
                  color: "#949494",
                  marginRight: "2em",
                  cursor: "pointer",
                }}
              >
                Terms of Service
              </CustomText>
            </div>
            <div onClick={() => navigate("/privacy")}>
              <CustomText
                style={{
                  fontWeight: "400",
                  fontSize: "16px",
                  color: "#949494",
                  cursor: "pointer",
                }}
              >
                Privacy Policy
              </CustomText>
            </div>
          </div>
        </div>
      </>
    </>
  );
}

export default SignupScreen;
